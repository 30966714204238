import React, { useEffect } from "react";
export default function WebappWebSocket(props) {

	const io = window.io;
	const wa = props.wa;
	const wah = wa.hint;
	const wam = wa.module;
	const was = wa.system;
	const renderCount = React.useRef(1);
	const conRef = React.useRef(null);
	let connection = conRef.current;

	useEffect(() => {
		if (renderCount.current === 1) {
			wa.webSocket.send = socketSend;
			socketConnect();
			props.childRendered();
		}
		renderCount.current++;
	});

	function socketConnect() {

		connection = io(document.location.href, { path: "/Websocket/" });
		wa.system.webSocket = connection;
		
		connection.on('hint', (args) => {
			args = JSON.parse(args);
			wah.addHint(args.hint);
			return true;
		});
		
		connection.on('new-posting', (args) => {
			args = JSON.parse(args);
			if (typeof wa.module[args.module] !== 'undefined') {
				// wa.module[args.module].updatePostingsUnread(9);
				wa.module[args.module].reload();
			}
			wa.system.loadActiveMember();
		});

		connection.on('read-posting', (args) => {
			args = JSON.parse(args);
			if (args.memberid === was.memberId) {
				console.log('Read Posting...');
				was.loadActiveMember();
				if (typeof wam[args.module] !== 'undefined') {
					wam[args.module].reload();
				}
			}
		});
	
	
		connection.on('login', (args) => {
			args = JSON.parse(args);
			wa.system.loadActiveMember();
		});

		connection.on('logout', (args) => {
			args = JSON.parse(args);
			wa.system.loadActiveMember();
		});

		connection.on('update', (args) => {
			console.log('Update Member...');
			/* 
			const dregg = new Error('I was called').stack;
			const stack = dregg.split('\n');
			console.log(dregg);
			console.log(stack[0]);
			console.log(stack[2]);
			*/
		});

		connection.on('smilies-update', (args) => {
			was.axGet('/Backend/webapp.php?module=System&task=ReloadSessionData', (result) => {
				was.setSmilies(result.data.content.smilies);
			});
		});

		connection.on('software-update', (args) => {
			window.location.reload();
		});

		connection.on('ping', (args) => {
			console.log('Ping.....' + args);
			return true;
		});
		
		connection.on('connect', (args) => {
			wa.module.reloadForums();
			wa.system.loadActiveMember();
			console.log('Connect...');
		});

		connection.on('disconnect', (args) => {
			console.log('Disconnect...');
		});

	}

	function socketSend(type, args, handleResult = false) {
		args.member = wa.system.memberName;
		args.memberid = wa.system.memberId;
		// console.log(args);
		try {
			if (handleResult === false) {
				connection.emit(type, JSON.stringify(args));				
			}
			else {
				connection.emit(type, JSON.stringify(args), (response) => {
					// console.log('Response: ' + response);
					handleResult(JSON.parse(response));
				});
			}
		} catch (error) {
			console.log('socket.io error');
		}
	}


	return (
		<React.Fragment />
	);


}

