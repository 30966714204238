import React from 'react';
import Avatar from "../Member/Avatar";

export default function Posting(props) {

	const was = props.wa.system;
	const wam = props.wa.module;
	const wau = props.wa.utility;
	const posting = props.posting;
	const postingSelected = (props.selectedPostingNo === posting.postingno && props.threadMode) ? 'posting-selected' : "";
	const postingUnread = (posting.postingno > props.lastPostingRead) ? 'posting-unread' : "";
	const hideContent = (typeof props.hideContent === 'undefined') ? was.forumHideContent : props.hideContent;
	const hideDiscussion = (typeof props.hideDiscussion === 'undefined') ? was.forumHideDiscussion : props.hideDiscussion;
	const hideAvatar = (typeof props.hideAvatar === 'undefined') ? was.forumHideAvatar : props.hideAvatar;
	const hideDate = (typeof props.hideDate === 'undefined') ? was.forumHideDate : props.hideDate;
	const hideMember = props.wa.utility.getProperty(props.hideMember, false);

	// Diese Variablen werden in makeContent gesetzt
	let content = <></>;
	if (typeof posting.posting !== 'string') {
		console.log('Posting: ' + posting.posting);
		posting.posting = '';
	}
	// console.log(plainPosting);
	const postingText = wau.translatePosting(posting.posting);
	const postingLines = postingText.split("<br />");
	const firstPostingLine = postingLines[0];
	// const oT = (postingLines.length === 1) ? " (oT) " : " ";
	// const title = (posting.visible === 1) ? '<span>' + firstPostingLine + '<i>' + oT + '</i></span>' : '<span>***</span>';

	/*
	const titleTag = 
		<React.Fragment>
			<span>
				<span
					id={props.moduleId + '-' + posting.postingno}
					className="posting-title "
					dangerouslySetInnerHTML={{ __html: title }}
				>
				</span>
			</span>
		</React.Fragment>
	*/


	// console.log(posting.postingno > props.lastPostingRead);
	// console.log(posting.postingno + ' > ' + props.lastPostingRead);

	function makePosting() {
		makeContent();
		const memberLevelLabel = ['', 'Admin', 'Mod', 'User', 'User'];
		const memberLevel = (posting.memberlevel < 3) ? <span className="member-level">{memberLevelLabel[posting.memberlevel]}</span> : ''
		const memberClasses = ['', 'member-name-admin', 'member-name-mod', 'member-name-user', 'member-name-user'];
		const postingInfo = {
			"date": <span
				className={` posting-date ${(hideDate === 1) ? 'd-none' : 'd-inline'} `}
			>
				{posting.postdate}
			</span>,
			"member": <span
				className={` member-name ${memberClasses[posting.memberlevel]}`}
				href="#"
				onClick={
					(props.mode === 'list')
						?
						null
						:
						e => wam[props.moduleId].showMemberPostings(posting.memberid, posting.membername)
				}
			>
				{posting.membername}  {memberLevel}
			</span>,
			"iconReply": <i className="bi bi-three-dots" title="Antworten/Reagieren" onClick={e => postingAnswer(e)}></i>,
			"clipBoard": <i
				className="bi bi-copy"
				title="Beitrag in die Zwischenablage kopieren"
				onClick={e => props.copyToClipboard(e, posting.posting, posting.membername, posting.postdate)}>
			</i>,
			"modified": (posting.modified === 1) ? <i className="bi bi-pencil "/> : null
		};
		switch (props.mode) {
			case 'thread':
				return makeThread(postingInfo);
			case 'answer':
				return makeAnswerTag(postingInfo);
			case 'list':
				return makeList(postingInfo);
			default:
				break;
		}
	}

	function makeContent() {
		content = <></>;
		if (posting.posting !== "" && posting.visible === 1) {
			content =
				<React.Fragment>
					<span
						id={posting.postingno + '-content'}
						className={` posting-content ${(postingLines.length > 10 && ((typeof was.postingUnshrink[posting.postingno] === 'undefined') || (was.postingUnshrink[posting.postingno] === false))) ? 'posting-shrink' : ''}`}
						dangerouslySetInnerHTML={{ __html: (posting.visible === 1) ? getPosting() : '***' }}
					/>
					{(postingLines.length > 10)
						?
						<span>
							<span
								id={posting.postingno + '-more'}
								className={`bi bi-plus-square posting-content-more ${(typeof was.postingUnshrink[posting.postingno] === 'undefined') || (was.postingUnshrink[posting.postingno] === false) ? '' : 'd-none'}`}
								title="Kompletten Beitrag anzeigen..."
								onClick={e => togglePostingExpand(e, posting.postingno)}
							/>
							<span
								id={posting.postingno + '-shrink'}
								className={`bi bi-dash-square posting-content-more ${(typeof was.postingUnshrink[posting.postingno] === 'undefined') || (was.postingUnshrink[posting.postingno] === false) ? 'd-none' : ''}`}
								title="Vorschau anzeigen..."
								onClick={e => togglePostingExpand(e, posting.postingno)}
							/>
						</span>

						:
						null
					}
				</React.Fragment>
		}
	}

	function togglePostingExpand(event, postingNo) {
		event.preventDefault();
		const postingId = postingNo + '-content';
		const posting = document.getElementById(postingId);
		const more = document.getElementById(postingNo + '-more');
		const shrink = document.getElementById(postingNo + '-shrink');

		if ((typeof was.postingUnshrink[postingNo] === 'undefined') || (was.postingUnshrink[postingNo] === false)) {
			was.postingUnshrink[postingNo] = true;
			posting.classList.remove('posting-shrink');
			more.classList.add('d-none');
			shrink.classList.remove('d-none');
		}
		else {
			was.postingUnshrink[postingNo] = false;
			posting.classList.add('posting-shrink');
			more.classList.remove('d-none');
			shrink.classList.add('d-none');
		}
	}

	function getPosting() {
		if (hideContent === 1) {
			return firstPostingLine;
		}
		else {
			// let posting = firstPostingLines.join('<br />');
			return postingText;
		}
	}

	function makeThread(postingInfo) {
		return ((hideAvatar) ? makeThreadTag(postingInfo) : makeThreadTagAvatar(postingInfo));
	}

	function makeTopic() {
		const topic = <span
			className="posting-topic"
			onClick={(e) => {
				props.setTopic(posting.topicid, posting.topicname);
			}}
		>
			{posting.topicname}
		</span>

		return topic;

	}

	function makeThreadTag(postingInfo) {
		const topic = makeTopic();
		const memberTag =
			<div className='posting-head d-flex'>
				<div >
					{postingInfo.member}
				</div>
				{postingInfo.date}
				<span>
					{(posting.topicname !== '') ? topic : null}
				</span>
				{postingInfo.iconReply}
			</div>;

		return (
			<div key={posting.postingno} className={`postings ${postingSelected} ${postingUnread}`} >
				<div className="thread thread-no-avatar">
					<div>
						{memberTag}
						<div >
							{postingInfo.modified} {content}
						</div>
					</div>
				</div>
			</div>
		);
	}

	function makeThreadTagAvatar(postingInfo) {
		const avatar = <Avatar memberLevel={posting.memberlevel} avatarText={posting.avatartext} />;
		const topic = makeTopic();

		const memberTag =
			<div className='posting-head d-flex'>
				<div >
					{postingInfo.member}
				</div>
				{postingInfo.date}
				<span>
					{(posting.topicname !== '') ? topic : null}
				</span>
				{postingInfo.iconReply}
			</div>;

		return (
			<div key={posting.postingno} className={`postings ${postingSelected} ${postingUnread}`} >
				<div className="thread">
					{avatar}
					<div>
						{memberTag}
						<div >
						{postingInfo.modified}{content}
						</div>
					</div>
				</div>
			</div>
		);

	}

	/*
	function makeAnswerTagWaelder(postingInfo) {
		return (
			<div key={posting.postingno}
				className={` postings ${(hideDiscussion === 1) ? 'd-none' : 'd-block'} ${postingSelected} ${postingUnread}`}
				style={{ paddingLeft: ''.concat(props.paddingLeft + (posting.threadcodepos * 10)).concat('px') }}
			>
				<div >
					<span className={`answer align-text-bottom`} >
						{titleTag} 
						{postingInfo.member}
						{postingInfo.date}
						{postingInfo.iconReply}
						<br />
					</span>
				</div>
			</div>
		)
	}
	*/

	function makeAnswerTag(postingInfo) {
		return (
			<div key={posting.postingno}
				className={` postings ${(hideDiscussion === 1) ? 'd-none' : 'd-block'} ${postingSelected} ${postingUnread}`}
				style={{ paddingLeft: ''.concat(props.paddingLeft + (posting.threadcodepos * 10)).concat('px') }}
			>
				<div >
					<span className={`answer align-text-bottom`} >
						{postingInfo.member}
						{postingInfo.date}
						{postingInfo.iconReply}
					</span>
					<div id={'content-' + posting.postingno} className={` answer-content `} >
						{postingInfo.modified}{content}
					</div>
				</div>
			</div>
		)
	}

	function makeList(postingInfo) {
		return (
			<div key={posting.postingno}
				className={` ${(hideDiscussion === 1) ? 'd-none' : 'd-block'} `}
				style={{ paddingLeft: ''.concat(props.paddingLeft + (posting.threadcodepos * 10)).concat('px') }}
			>
				<div >
					<span className={`answer align-text-bottom`} >
						<span onClick={props.onClick}>
							{(hideMember) ? null : postingInfo.member}
							{postingInfo.date}
						</span>
						{postingInfo.iconReply}
						{postingInfo.clipBoard}<br />
					</span>
					<div id={'content-' + posting.postingno} className={` answer-content posting-list-content`} onClick={props.onClick} >
						{content}
					</div>
				</div>
			</div>
		)
	}

	function postingAnswer(e) {
		e.preventDefault();
		props.showDialogAnswer(props.getPostings, posting.topicid, posting.topicname, posting.postingno, posting.visible, posting.posting, posting.memberid, posting.membername, posting.postdate);
	}

	return (
		<span id={"posting-" + posting.postingno}>
			{makePosting()}
		</span>
	)
};
