import React, { useEffect } from "react";

export default function WebappNavigation(props) {

	const wa = props.wa;
	const renderCount = React.useRef(1);

	useEffect(() => {
		if (renderCount.current === 1) {

			// Zeiger auf Funktionen
			props.childRendered();			
		}
		renderCount.current++;
	});

	wa.navigation.init = init;
	wa.navigation.hideAll = hideAll;

	function init(id) {
		if (typeof wa.navigation[id] == 'undefined') {
			wa.navigation[id] = {};
			wa.navigation[id].hide = () => {};
			wa.navigation[id].init = () => {};
			wa.navigation[id].show = () => {};
			wa.navigation[id].makeMenu = () => {};
		}
	}

	function hideAll() {
		wa.system.setModal(false);
		for (const key in wa.navigation) {
			if (typeof wa.navigation[key].hide != 'undefined') {
				wa.navigation[key].hide();
			}
		}

	}
	
	

	return (
		<React.Fragment />
	);

}

