import React from 'react';
import './CSS/App.css';
import './CSS/Dialog.css';
import './CSS/Form.css';
import './CSS/Member.css';
import './CSS/Hint.css';
import './CSS/Module.css';
// import './CSS/Table.css';
import Webapp from 'Components/App/Webapp';

export default function App() {



	// Verweise auf System-Funktionen und System-Variable


	return (
		<Webapp / >
	);
}


