import React from 'react';
import Dialog from "Components/Core/Dialog";
import FormElement from 'Components/Core/Form/FormElement';

export default function DialogOk(props) {

	const wad = props.wa.dialog;
	const waf = props.wa.form;

	wad.init('ok');
	waf.init('ok');

	function handleClose() {
		wad['ok'].hide();
	}

	return (

		<Dialog id={props.id} wa={props.wa} title={props.title} classes="dialog-ok" closeIcon="1" modal={true}>
			<FormElement type="div" wa={props.wa} wafId={'ok'} readOnly={true} id="ok-text" title="Text" />
			<div className='div-button'>
				<button className="btn " type="button" onClick={event => handleClose()} >OK</button>
			</div>
		</Dialog>

	)
};
