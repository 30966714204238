import React from "react";
import Module from 'Components/Core/Module';
import Form from "Components/Core/Form/Form";
import FormElement from '../Core/Form/FormElement';
// import 'CSS/App/HintToMembers.css';

export default function HintToMembers(props) {

	const waf = props.wa.form;	
	const wam = props.wa.module;	
	const was = props.wa.system;	
	const waw = props.wa.webSocket;	
	
	const wafId = props.id;

	React.useEffect(() => {
		if (! was.isMobileDevice()) waf.focus('hint-to-members', 'hint');
	});



	waf.init(wafId, {
	});

	wam.init(props.id, {
		"title": 'Nachricht an alle',
		"onClose": () => {
			waf.destroy(wafId);
		}
	});

	function onSubmit(event) {
		event.preventDefault();
		waw.send('hint', { "hint": waf.get(wafId, 'hint') });
		waf.set(wafId, 'hint', '');
	}

	return (
		<Module id={props.id} wa = {props.wa} title={props.title} display={props.display}  displayTitle="d-block" closeButton="1">
			
			<Form 	wa={props.wa} 
					wafId={wafId} 
					module="Member" 
					sendButtonTitle="Senden"
					onSubmit={(e) => onSubmit(e)}
					focusElement="hint" 
					classes="">	
					<FormElement wa={props.wa} wafId={wafId} id="hint" title="Hinweis" />
			</Form>
		</Module>
	);

}

