import React from "react";
import axios from "axios";
import MemberProfile from "Components/Member/MemberProfile";
// import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";

export default function WebappSystem(props) {

	const wa = props.wa;

	const renderCount = React.useRef(1);

	const defaultAction = () => { };

	const wam = wa.module;
	const was = wa.system;
	const wau = wa.utility;

	// Webapp Version
	was.version = '1.1';
	was.buildNo = 0; 	                                              		// Wird über init in App.js gesetzt


	// Variables
	was.smilies = {};
	was.smiliesSortTranslation = null;
	was.members = {};
	was.topics = {};
	was.postingsUnread = {'forum':0, 'admin':0};						// Wird in MemberListBasis und Postings gesetzt
	was.postingsLastUnread = {'forum':0, 'admin':0};					// Wird in MemberListBasis und Postings gesetzt
	was.postingsUnreadHint = [];										// Wird in MemberListBasis und Postings gesetzt. 
	was.postingsUnreadCount = 0;										// Wird in dieser Komponenten in der Funktion setFavicon gesetzt
	was.postingUnshrink = []											// Wird in der Komponente Posting gesetzt und enthält die postingno eines großen Postings, das komplett angezeigt wird. 
	was.privatePostingsUnreadCount = 0;									// Wird in dieser Komponenten in der Funktion setFavicon gesetzt
	was.webSocket = null;												// Wird in der Komponente WebappWebSocket gesetzt
	was.logTimout = null;												// Handle auf Timeout-Funktion. Wird in der Funktion errorLog gesetzt
	was.loadMemberListTimeOut = null;									// Handle auf Timeout-Funktion. Wird in der Funktion loadMemberList der Komponente MemberListBasis gesetzt


	// Functions
	was.addHint = defaultAction;
	was.addWatchClick = addWatchClick;
	was.delWatchClick = delWatchClick;
	was.addWatchKey = addWatchKey;
	was.delWatchKey = delWatchKey;
	was.addNewThread = addNewThread;
	was.axGet = axGet;
	was.axPost = axPost;
	was.getTime = getTime;
	was.getProjectInfo = getProjectInfo;
	was.isAdmin = isAdmin;
	was.isMobileDevice = isMobileDevice;
	was.loadSoftwareUpdate = loadSoftwareUpdate;
	was.makeMemberList = defaultAction;									// Wird in der Komponente MemberListBasis gesetzt
	was.onClick = onClick;
	was.onKey = onKey;
	was.openNewThread = openNewThread;
	was.openProfile = openProfile;
	was.openPrivateForum = () => { };									// Wird in webapp.js gesetzt
	was.sendSmiliesUpdate = sendSmiliesUpdate;
	was.sendSoftwareUpdate = sendSoftwareUpdate;
	was.setFavicon = setFavicon;
	was.setSmilies = setSmilies;
	was.reportError = reportError;
	was.updatePostingsUnread = updatePostingsUnread;
	was.logout = logout;
	was.unlock = unlock;
	was.webAppLoaded = defaultAction;									// Wird in der Komponente Webapp gesetzt

	was.hostName = document.location.hostname;
	was.webapp = 0;                                                   	// Wird in app.js und NavigationTop.js im useEffect-Hook gesetzt. 0: nicht gerendert, 1: app.js ohne Module und Blöcke gerendert, 2: app.js mit Module und Blöcke gerendert

	was.watchClick = [];
	was.watchKey = [];

	// Level 1=root, 2=admin, 3=user, 4=guest
	was.memberLevel = 4;												// Wird in app.js in der Function webappRendered gesetzt.  	
	was.memberLevelLabel = ['', 'Admin', 'Moderator', 'User', 'Gast'];

	was.memberId = -1;													// Wird in app.js in der Function webappRendered gesetzt. 	
	was.memberName = '';												// Wird in app.js in der Function webappRendered gesetzt.
	was.avatarText = 'X';												// Wird in app.js in der Function webappRendered gesetzt.

	React.useEffect(() => {

		if (renderCount.current === 1) {
			const getSystemData = async () => {
				/*
				let url = 'Backend/webapp.php?module=Member&task=GetMembers';
				let result = await axios.get(url);
				was.members = JSON.parse(result.data.content);
				*/

				let url = 'Backend/webapp.php?module=Posting&task=GetTopics';
				let result = await axios.get(url);
				was.topics = JSON.parse(result.data.content);
				// console.log(was.topics);
				props.childRendered();
			};
			getSystemData();
		}
		renderCount.current++;
	});


	// System Labels
	was.application = 'Forum';                                        		// Wird über init in App.js gesetzt

	// Settings
	was.darkTheme = false;                                             		// Wird über init in App.js gesetzt
	was.darkThemeVariant = false;                                      		// Wird über init in App.js gesetzt
	was.forumClass = '';	                                           		// Wird über init in App.js gesetzt
	was.forumHideContent = 'd-block';	                               		// Wird über init in App.js gesetzt
	was.forumHideDiscussion = 'd-block';                             		// Wird über init in App.js gesetzt
	was.forumHideImages = '';			                               		// Wird über init in App.js gesetzt
	was.forumHideOT = 'd-block';	         	                      		// Wird über init in App.js gesetzt
	was.forumHideAvatar = true;         	                      			// Wird über init in App.js gesetzt
	was.forumHideDate = 'd-block';	         	                      		// Wird über init in App.js gesetzt
	was.forumMemberColored = 0;		         	                      		// Wird über init in App.js gesetzt
	was.forumFontBold = 0;			         	                      		// Wird über init in App.js gesetzt
	was.forumSendWithEnter = 0;			         	                      	// Wird über init in App.js gesetzt

	// System Functions

	was.loadActiveMember = defaultAction	                        			// Zeigt auf die Function "makeActiveList" aus der MemberList-Komponente und wird aus dieser gesetzt.  
	was.loadOpenWindows = defaultAction	                        				// Zeigt auf die Function "makeListing" aus der OpenWindowsLeft-Komponente und wird aus dieser gesetzt.  
	was.activateNavigation = defaultAction;										// Zeigt auf die Function "activateNavigation" aus App.js. Wird in App.js gesetzt. 
	was.addModule = defaultAction;                      	         			// Zeigt auf die Function "addModule" aus App.js. Wird in App.js gesetzt. 
	was.addNavigation = defaultAction;                      		      		// Zeigt auf die Function "addNavigation" aus App.js. Wird in App.js gesetzt. 
	was.setModal = defaultAction;				                    			// Zeigt auf die Function "modal" aus Modal.js. Wird in Modal.js gesetzt. 
	was.removeModule = defaultAction;                            				// Zeigt auf die Function "removeModule" aus App.js. Wird in App.js gesetzt. 
	was.showMemberList = false;                                     			// Wird in App.js gesetzt, sobald sich die Größe des Fensters ändert. 

	function updatePostingsUnread() {
		const currentModule = wam.current;
		setPostingsUnread();
		was.renderForum();
		for (const key in was.postingsUnread) {
			if ((key !== 'forum' && key !== 'admin' ) && typeof wa.module[key] === 'undefined') {
				const memberId = wau.getConversationalPartner(key);
				if(typeof was.members[memberId] !== 'undefined') {
					was.openPrivateForum(memberId, was.members[memberId].name, was.members[memberId].level, false);
				}
			}
		}
		wam.activate(currentModule);
	}

	function setFavicon(hRef) {
		const favicon = document.getElementById("favicon");
		favicon.href = hRef
	}

	function setPostingsUnread() {
		was.postingsUnreadCount = 0;
		was.privatePostingsUnreadCount = 0;
		for (const key in was.postingsUnread) {
			was.postingsUnreadCount += was.postingsUnread[key];
			was.privatePostingsUnreadCount = (key !== 'forum') ? was.privatePostingsUnreadCount += was.postingsUnread[key] : was.privatePostingsUnreadCount;
        }
		const projectInfo = getProjectInfo();
		const hRef = (was.postingsUnreadCount > 0) ? projectInfo.srcFaviconHint : projectInfo.srcFavicon;
		setFavicon(hRef);
		document.title = was.application + ' (' + was.postingsUnreadCount + ' ungelesene Nachrichten)';
	}

	function setSmilies(smilies) {
		smilies = JSON.parse(smilies);
		const smiliesSelect = {};
		Object.entries(smilies).forEach(([key, value]) => {
			Object.entries(value).forEach(([code, image]) => {
				// console.log('code: ' + code + ' - image: ' + image);
				smiliesSelect[code] = image;
			});
		});
		was.smilies = smiliesSelect;
		// console.log(was.smilies);
	}
	function axGet(url, handleResult) {
		// url = document.location.href + url;
		axios.get(wau.getUrl(url))
			.then(result => {
				handleResult(result);
			});
	}

	function axPost(url, data, handleResult) {
		// console.log(data);
		axios.post(wa.utility.getUrl(url), data)
			.then(result => {
				handleResult(result);
			});
	}

	function isAdmin() {
		if (wa.system.memberLevel < 3) {
			return true;
		}
		else {
			return false;
		}
	}

	function reportError(message) {
		addNewThread('admin', 0, message.stack)
	}	

	function isMobileDevice() {
		return ((document.body.clientWidth < 1024) ? true : false);
	}
	function getTime(date) {
		if (date === undefined) {
			date = new Date();
		}
		let time = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':';
		time += (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ':';
		time += (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
		return (time);
	}

	function getProjectInfo() {
		const info = {};
		const url = (new URL(window.location));
		const hostName = url.hostname.split(".");
		const domainName = hostName[hostName.length - 2] + '.' + hostName[hostName.length - 1];
		info.srcFavicon = 'project/' + domainName + '/favicon.png';
		info.srcFaviconHint = 'project/' + domainName + '/favicon-hint.png';
		info.srcLogo = 'project/' + domainName + '/logo.png';
		return info;
	}

	function loadSoftwareUpdate() {
		localStorage.setItem('software-update', 1);
		window.location.reload();
	}

	function openProfile(memberId, memberName, memberLevel) {
		const wam = wa.module;
		wam.hideAll();
		if (was.memberId !== memberId) {
			let moduleId = 'member-profile-' + memberId;
			let newTag = <MemberProfile id={moduleId} memberId={memberId} memberName={memberName} memberLevel={memberLevel} wa={wa} title={memberName + " (Profil)"} display="d-block" />;
			was.addModule(newTag);
		}
		else {
			// Eigene Einstellungen öffnen
			wam.activate('settings');
		}

	}

	function sendSoftwareUpdate() {
		wa.webSocket.send('software-update', {  });
	}

	function sendSmiliesUpdate() {
		wa.webSocket.send('smilies-update', {  });
		was.axGet('/Backend/webapp.php?module=System&task=ReloadSessionData', (result) => {
			// was.smilies = JSON.parse(result.data.content.smilies);
			was.setSmilies(result.data.content.smilies);

		});
	}

	function addWatchClick(id, funcRef) {
		was.watchClick[id] = funcRef;
	}

	function delWatchClick(id, funcRef) {
		if (id in was.watchClick) {
			delete was.watchClick[id];
		}
	}

	function addWatchKey(id, funcRef) {
		was.watchKey[id] = funcRef;
	}

	function delWatchKey(id, funcRef) {
		if (id in was.watchKey) {
			delete was.watchKey[id];
		}
	}

	function onClick(event) {
		for (const key in was.watchClick) {
			was.watchClick[key](event);
		}
	}

	function onKey(event) {
		for (const key in was.watchKey) {
			was.watchKey[key](event);
		}
	}

	function logout() {
		localStorage.removeItem('token');
		let url = 'https://'.concat(document.location.hostname, '/Backend/webapp.php?module=Member&task=Logout');
		axios.post(url).then(result => {
			// waw.socketSend(props.moduleId, 'logout', "", was.memberName);
			wa.webSocket.send('logout', { "module": props.moduleId, "member": was.memberName, "memberlevel": was.memberLevel });
			// wau.postingBackupsRemove();
			sessionStorage.clear();
			window.location.reload();
		});
	}

	function unlock() {
		let url = 'Backend/webapp.php?module=Posting&task=Unlock';
		axios.post(url).then(result => {
			console.log('Database unlocked');
		});
	}

	function addNewThread(forumId, topicId, posting) {
		let url = 'Backend/webapp.php?module=Posting&task=SaveNewPosting&forumId=' + forumId;
		const fields = {};
		fields['posting'] = posting;
		fields['topicid'] = topicId;
		axios.post(url, fields).then(result => {
			return (result.data.returncode > 1) ? true : false;
		});
	}

	function openNewThread() {
		var forumId = wam.getForumId();
		wam[forumId].showDialogNewThread();
		wam[forumId].setTab('postings'); // Funktion wird in tabs.js definiert
		wam.activate(forumId);
	}

	return (
		<React.Fragment />
	);


}
