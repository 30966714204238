import React from "react";

export default function WebappUtility(props) {

	const wa = props.wa;
	const $ = window.$;
	const waf = wa.form;
	const wah = wa.hint;
	const wam = wa.module;
	const was = wa.system;
	const wau = wa.utility;
	const waw = wa.webSocket;
	const renderCount = React.useRef(1);
	const componentId = React.useRef(0);

	// Debug Infos
	let recentUrl = '';													// Wird über die Funktion getUrl gesetzt.

	React.useEffect(() => {
		if (renderCount.current === 1) {
			props.childRendered();
		}
		renderCount.current++;
	});


	wau.checkPaste = checkPaste;
	wau.fillZero = fillZero;
	wau.getTime = getTime;
	wau.getStatus = getStatus;
	wau.getPrivateForumId = getPrivateForumId;
	wau.getConversationalPartner = getConversationalPartner;
	wau.getProperty = getProperty;
	wau.getUrl = getUrl;
	wau.getComponentId = getComponentId;
	wau.nl2br = nl2br;
	wau.postingBackup = postingBackup;
	wau.postingBackupRemove = postingBackupRemove;
	wau.postingRestore = postingRestore;
	wau.replaceChar = replaceChar;
	wau.postingBackupsRemove = postingBackupsRemove;
	wau.translatePosting = translatePosting;
	wau.validPassword = validPassword;

	function getStatus() {
		const current = new Date();
		const date = fillZero(current.getDate()) + '.' + fillZero((current.getMonth() + 1)) + '.' + current.getFullYear()
		const screen = document.body.clientWidth + 'px * ' + document.body.clientHeight + 'px';
		let localStorageKeys = '';
		let sessionStorageKeys = '';
		Object.keys(localStorage).forEach(function (key) {
			localStorageKeys += key + ': ' + localStorage.getItem(key) + '\n';
		});
		Object.keys(sessionStorage).forEach(function (key) {
			sessionStorageKeys += key + ': ' + sessionStorage.getItem(key) + '\n';
		});
		const status =
			"Enthält vertrauliche Informationen, nicht an Dritte weitergeben!!!\n\n" +
			"Applikation: " + wa.system.application + '\n' +
			"Browser: " + navigator.userAgent + '\n' +
			"Webapp Version: " + was.version + ', Build ' + was.buildNo + '\n' +
			"React Version: " + React.version + '\n' +
			"jQuery Version: " + $().jquery + '\n\n' +
			"Screen: " + screen + '\n' +
			"Zuletzt verwendete URL: " + recentUrl + '\n\n' +
			"LocalStorage: \n" + localStorageKeys + '\n' +
			"SessionStorage: \n" + sessionStorageKeys + '\n' +
			"Erstellt am: " + date + ', ' + fillZero(current.getHours()) + ':' + fillZero(current.getMinutes()) + ':' + fillZero(current.getSeconds()) + ' Uhr ';
		return status;
	}

	/*
		Posting Functions
	*/

	function postingBackup(wafId, postingNo, value) {
		// const storageId = ((props.answerTo === 0) ? '' : props.answerTo + '-') + wafId + '-' + postingId;
		sessionStorage.setItem(getStorageId(wafId, postingNo), value);
	}

	function postingBackupRemove(wafId, postingNo) {
		const storageId = getStorageId(wafId, postingNo);
		if (sessionStorage.getItem(storageId) !== null) {
			sessionStorage.removeItem(storageId);
		}
	}

	function postingRestore(wafId, postingNo) {
		const postingBackup = sessionStorage.getItem(getStorageId(wafId, postingNo));
		// console.log(postingBackup);
		if (postingBackup !== null) {
			waf.set(wafId, 'posting', postingBackup);
		}
		else {
			waf.set(wafId, 'posting', '');
		}
		return postingBackup;
	}

	function getStorageId(wafId, postingNo) {
		const storageId = postingNo.toString().padStart(10, '0') + '-' + wafId + '-posting';
		return storageId;
	}

	function postingBackupsRemove() {
		Object.keys(sessionStorage).forEach(function (key) {
			if (key !== 'token') {
				sessionStorage.removeItem(key);
			}
		});
	}

	function fillZero(value) {
		return (value < 10) ? '0' + value : value;
	}

	function getPrivateForumId(memberId) {
		if (memberId === 'forum') {
			return memberId
		}
		else {
			return (was.memberId > memberId) ? memberId + '-' + was.memberId : was.memberId + '-' + memberId;
		}
	}

	function checkPaste(text, newThread = true, openNewThread = () => { }) {
		const forumId = wam.getForumId();
		const wafId = forumId + '-' + ((newThread) ? 'new-thread' : 'answer');
		let rc = false;
		let newText = '';
		if (text !== "") {
			if (text.substr(0, 7) === 'http://' || text.substr(0, 8) === 'https://') {
				rc = true;
				was.setModal(true);
				const setPostingText = (text) => {
					openNewThread();
					// console.log(waf.get(wafId, 'posting'));
					const curPos = waf[wafId].elements['posting'].reference.current.selectionStart;
					const curVal = waf.get(wafId, 'posting');
					const leftValue = curVal.substring(0, curPos);
					const rightValue = curVal.substring(curPos);
					// waf.set(wad[props.id].wafId, 'posting', leftValue + '[' + tag + ']' + text + '[/' + tag + ']' + rightValue);
					// ((leftValue === '') ? '' : '\r\n') + 
					const newValue = leftValue + ((leftValue === '') ? '' : '\r\n') + text + rightValue;
					wau.postingBackup(wafId, 'posting', newValue);
					waf.set(wafId, 'posting', newValue);
					waf[wafId].enableSendButton(waf.checkChanged(wafId));
					was.setModal(false);
				}


				const checkImage = (url) => {
					let image = new Image();
					image.onload = function () {
						console.log('Loaded: ' + text);
						// setPostingText('[img]' + text + '[/img]'+'\r\n');
						setPostingText(`[img]${text}[/img]\r\n`);
					}
					image.onerror = function () {
						const progressHint = wah.addHint('URL prüfen...', false);
						waw.send('get-preview', { 'url': text }, (result) => {
							// console.log('Result: ' + result);
							wam.activate(forumId);
							newText = (result.title !== '') ? `"${result.title}"\r\n\n` : '';
							newText += `[url=${text}]${text}[/url]\r\n`;
							if (result.description !== '') {
								newText += '\r\n"' + result.description + '"\r\n';
							}
							if (result.image !== '') {
								newText += ' \r\n[img]' + result.image + '[/img]';
							}
							setPostingText(newText);
						});
						wah.removeHint(null, progressHint);
					}
					image.src = url;
				}
				checkImage(text);
			}
		}
		return rc;
	}

	function getConversationalPartner(forumId) {
		if (forumId === 'forum') {
			return forumId;
		}
		else {
			const memberIds = forumId.split('-');
			if (parseInt(memberIds[0]) === was.memberId) {
				return memberIds[1];
			}
			else {
				return memberIds[0];
			}
			// console.log('Forum :' + forumId + ' - ' + ' MemberId: ' + memberId);
		}
	}

	function translatePosting(posting) {
		posting = nl2br(posting);
		posting = translateSmilies(posting);
		posting = translateBBCode(posting);

		return posting;
	}
	function translateSmilies(posting) {
		try {
			if (posting.search(':-') > -1) {
				Object.entries(was.smilies).forEach(([key, value]) => {
					posting = posting.replaceAll(':-' + key, value);
					if (posting.search(':-') === -1) {
						throw new Error("Break the loop.")
					}
				});
			}
		}
		catch (error) {

		}
		return posting;
	}

	function translateBBCode(posting) {
		posting = posting.replaceAll('[ul]', '<ul>');
		posting = posting.replaceAll('[/ul]', '</ul>');
		posting = posting.replaceAll('[li]', '<li>');
		posting = posting.replaceAll('[/li]', '</li>');
		posting = posting.replaceAll('[b]', '<b>');
		posting = posting.replaceAll('[/b]', '</b>');
		posting = posting.replaceAll('[i]', '<i>');
		posting = posting.replaceAll('[/i]', '</i>');
		posting = posting.replaceAll('[u]', '<span style="text-decoration:underline;">');
		posting = posting.replaceAll('[/u]', '</span>');
		posting = posting.replaceAll('[quote]', '<span class="posting-quote">');
		posting = posting.replaceAll('[/quote]', '</span>');
		posting = posting.replaceAll('[smiley]', '<span class="smiley" src="');
		posting = posting.replaceAll('[/smiley]', '"/>');
		posting = posting.replaceAll('[img]', '<img src="');
		posting = posting.replaceAll('[/img]', '"/>');
		posting = translateBBCodeUrl(posting);
		return posting;

	}

	function translateBBCodeUrl(posting) {
		let newPosting = posting.replaceAll('[url=', '<a target="_blank" rel="nofollow ugc noopener" href="');
		let closeTagPos = newPosting.indexOf('[/url]');
		let curPos;
		while (closeTagPos > -1) {
			curPos = closeTagPos - 1;
			while (curPos > -1) {
				if (newPosting[curPos] === ']') {
					newPosting = newPosting.replace('[/url]', '</a>');
					newPosting = wau.replaceChar(newPosting, '">', curPos);
					break;
				}
				curPos--;
			}
			closeTagPos = newPosting.indexOf('[/url]', closeTagPos + 1);
			// console.log('closeTagPos: ' + closeTagPos);
		}
		// newPosting = newPosting.replaceAll('[/url]', '</a>');
		return newPosting;
	}


	function nl2br(str) {
		if (str.indexOf('<br />') === -1) {
			var breakTag = '<br />';
			var replaceStr = '$1' + breakTag;
			return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
		}
		else {
			return str;
		}
	}

	function replaceChar(origString, replaceChar, index) {
		let firstPart = origString.substr(0, index);
		let lastPart = origString.substr(index + 1);

		let newString = firstPart + replaceChar + lastPart;
		return newString;
	}

	function getTime(date) {
		if (date === undefined) {
			date = new Date();
		}
		let time = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':';
		time += (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ':';
		time += (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
		return (time);
	}

	function getProperty(property, defaultValue) {
		return (typeof property === 'undefined') ? defaultValue : property;
	}

	function getUrl(url) {
		// const location = document.location.href;
		const delimiter = (url[0] !== '/') ? '/' : '';
		url = delimiter + url;
		recentUrl = url;
		return url;
	}

	function getComponentId() {
		componentId.current++;
		return componentId.current;
	}

	function validPassword(newPassword, oldPassword = false) {
		if (oldPassword === false || newPassword !== oldPassword) {
			if (newPassword.length >7 && newPassword.length <21 && newPassword.search(/[a-z]/) > -1 && newPassword.search(/[A-Z]/) > -1 && newPassword.search(/[0-9]/) > -1) {
				return true;
			}
		}
		return false;
	}	

	return (
		<React.Fragment >
		</React.Fragment>
	);

}

