import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import 'CSS/Forum/DialogAddBBCode.css';

export default function DialogAddBBCode(props) {

	const wab = props.wa.block;
	const wad = props.wa.dialog;
	const waf = props.wa.form;

	const tag = (typeof props.tag === 'undefined') ? 'url' : props.tag;
	const classes = (typeof props.classes === 'undefined') ? '' : props.classes

	wad.init(props.id, {
		"setFocus": props.setFocus
	});

	waf.init(props.id, {
		"ok": send,
	});

	function send(event) {
		event.preventDefault();
		switch (tag) {
			case 'b':
			case 'u':
			case 'i':
				addFormatting();
				break;
			case 'ul':
				addList();
				break;
			case 'url':
				addLink();
				break;
			default:
				addLink();
		}
		props.reset();
		waf[wad[props.id].wafId].onFormChanged();
	}

	function addFormatting() {
		wab.modal.hide();
		wad[props.id].hide();

		const curPos = waf[wad[props.id].wafId].elements['posting'].reference.current.selectionStart;
		const curVal = waf.get(wad[props.id].wafId, 'posting');
		const leftValue = curVal.substring(0, curPos);
		const rightValue = curVal.substring(curPos);
		const text = waf.get(props.id, 'text');
		waf.set(wad[props.id].wafId, 'posting', leftValue + '[' + tag + ']' + text + '[/' + tag + ']' + rightValue);
	}

	function addList() {
		wab.modal.hide();
		wad[props.id].hide();

		const linesText = waf.get(props.id, 'text');
		const lines = linesText.split('\n');
		let text = '[ul]';
		lines.forEach(
			function (value) {
				if (value.trim() > '') {
					text += '[li]' + value + '[/li]';
				}
			})
		text += '[/ul]';
		const curPos = waf[wad[props.id].wafId].elements['posting'].reference.current.selectionStart;
		const curVal = waf.get(wad[props.id].wafId, 'posting');
		const leftValue = curVal.substring(0, curPos);
		const rightValue = curVal.substring(curPos);
		
		waf.set(wad[props.id].wafId, 'posting', leftValue + text + rightValue);
	}

	function addLink() {
		wab.modal.hide();
		wad[props.id].hide();
		const curPos = waf[wad[props.id].wafId].elements['posting'].reference.current.selectionStart;
		const curVal = waf.get(wad[props.id].wafId, 'posting');
		const leftValue = curVal.substring(0, curPos);
		const rightValue = curVal.substring(curPos);
		const url = waf.get(props.id, 'url');
		const urlTitle = waf.get(props.id, 'url-title');
		waf.set(wad[props.id].wafId, 'posting', leftValue + '[url=' + url + ']' + urlTitle + '[/url]' + rightValue);
	}

	return (

		<Dialog id={props.id} wa={props.wa} title={props.title} classes={'dialog-add-content ' + classes} closeIcon="1" >
			<Form wa={props.wa} wafId={props.id} dialogId={props.id} sendButtonTitle="Übernehmen" classes="webapp-form" onSubmit={send}>
				{props.children}
			</Form>
		</Dialog>

	)
};
