import React from 'react';
import FormElement from 'Components/Core/Form/FormElement';
import DialogAddBBCode from './DialogAddBBCode';

export default function DialogAddLink(props) {

	const waf = props.wa.form;

	return (

		<DialogAddBBCode 
			id={props.id} 
			wa={props.wa} 
			title={props.title} 
			classes=" dialog-add-content dialog-add-link" 
			tag="url" 
			focus={() => {waf.setFocus(props.id, 'url')}} 
			reset={() => {	
				waf.set(props.id, 'url', '');
				waf.set(props.id, 'url-title', '');
			}}

		>
			<FormElement wa={props.wa} wafId={props.id} id="url-title" title="Titel" />
			<FormElement wa={props.wa} wafId={props.id} id="url" title="URL" />
		</DialogAddBBCode>

	)
};
