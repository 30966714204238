import React from 'react';
import 'CSS/FormSelect.css';

export default function FormSelectIcon(props) {

	const id = props.id + '-form';
	const dialogId = props.id + '-dialog';

	const classes = (typeof props.classes === 'undefined') ? '' : props.classes;

	const wad = props.wa.dialog;

	wad.init(dialogId);
	wad[dialogId].setValue = setSelectValue;

	React.useEffect(() => {
		return () => {
		}
	}, [props]);

	function setSelectValue(key, value) {
		// console.log(key + '-' + value);
		props.onSelect(key, value);
	}

	function showSelectionList() {
		wad[dialogId].showSelection();
	}


	return (
		<span id={id} className={` ${classes} `}
			title="Aktive Fenster"
			onClick={e => showSelectionList()}
		/>
	)
};
