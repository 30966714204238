import React from "react";
import FormElement from "Components/Core/Form/FormElement";
import NewPosting from 'Components/Forum/NewPosting';
import PostingContextMenu from "./PostingContextMenu";
import FormSelectText from 'Components/Core/Form/FormSelectText';
import DialogSelectText from 'Components/Core/Form/DialogSelectText';


export default function NewAnswer(props) {

	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;
	const wau = props.wa.utility;
	const waw = props.wa.webSocket;
	const [update, setUpdate] = React.useState(Date.now());

	const selectTopicId = props.moduleId + '-select-set-topic';

	const postingBackup = React.useRef('');

	function editPosting() {
		if (waf.isReadOnly(props.dialogId, 'base-posting')) {
			postingBackup.current = waf.get(props.dialogId, 'base-posting');
			waf.setReadOnly(props.dialogId, 'base-posting', false);
			// waf.focus(props.dialogId, 'base-posting');
			waf.setCursorPos(props.dialogId, 'base-posting', 0, 0)
		}
		else {
			waf.setReadOnly(props.dialogId, 'base-posting', true);
		}
	}

	function postingSaveEdit() {
		waf.setReadOnly(props.dialogId, 'base-posting', true);
		const fields = {};
		fields['posting'] = waf.get(props.dialogId, 'base-posting');
		fields['postingBackup'] = postingBackup.current;
		was.axPost('/Backend/webapp.php?module=Posting&task=PostingUpdate&postingNo=' + props.answerTo, fields, (result) => {
			if (result.data['returncode'] === 1) {
				// wah.addHint('Thema zugewiesen...', true)
				wam[props.moduleId].reload();
				waw.send('new-posting', { "module": props.moduleId, "member": was.memberName, "memberlevel": was.memberLevel, "postingno": -1 });

			}
		});
	}

	function postingCancelEdit() {
		waf.set(props.dialogId, 'base-posting', postingBackup.current);
		waf.setReadOnly(props.dialogId, 'base-posting', true);
		
	}
	function getTopicList() {
		const topicList = [{'id': 0, 'name': 'Kein Thema zuordnen'}].concat(was.topics);
		return topicList;			
	}

	function saveTopicId(topicId) {
		was.axGet('/Backend/webapp.php?module=Posting&task=SavePostingTopic&postingNo=' + props.answerTo + '&topicId=' + topicId, (result) => {
			if (result.data['returncode'] === 1) {
				// wah.addHint('Thema zugewiesen...', true)
				wam[props.moduleId].reload();
			}
		});
	}

	return (
		<React.Fragment key='div-answer'>
			<NewPosting
				wa={props.wa}
				id={props.moduleId + '-answer'}
				newPostingFunctions={props.newPostingFunctions}
				showDialogAnswer={props.showDialogAnswer}
				key="new-posting"
				titleText='Antwort: '
				moduleId={props.moduleId}
				forumId={props.forumId}
				answerTo={props.answerTo}
				dialogId={props.dialogId}
			>
				<PostingContextMenu
					wa={props.wa}
					moduleId={props.moduleId}
					forumId={props.forumId}
					dialogId={props.dialogId}
					answerTo={props.answerTo}
					registerDialog={props.registerDialog}
					postingQuote={props.postingQuote}
					copyToClipboard={props.copyToClipboard}
					editPosting={editPosting}
					postingSaveEdit={postingSaveEdit}
					postingCancelEdit={postingCancelEdit}
				/>
				<div className="select-topic">
					<FormSelectText
						id={selectTopicId}
						iconMode={false}
						wafId={props.moduleId + "-form-current-topic"}
						name="topicid"
						componentId={wau.getComponentId()}
						moduleId={props.id}
						wa={props.wa}
						disabled={(wad[props.dialogId].memberId === was.memberId || was.isAdmin()) ? false : true}
						title={(wad[props.dialogId].memberId === was.memberId || was.isAdmin()) ? "Diesem Beitrag ein Thema zuweisen." : "Kein Thema zugewiesen..."}
						alwaysShowSelection={false}
						afterSelect={(key, value) => {
							saveTopicId(key);

						}}
						resetIcon={false}

					/>
				</div>
				<div className="answer-to-textarea">
					<FormElement
						type="textarea"
						wa={props.wa}
						dialogId={props.dialogId}
						wafId={props.dialogId}
						id="base-posting"
						readOnly={true}
						name="body"
						title=""
						fieldValue=""
						rows="8"
						update={update}
						onChange={() => {
							setUpdate(Date.now());
						}} 
					/>
				</div>
				<DialogSelectText id={selectTopicId} moduleId={props.id} wa={props.wa} data={getTopicList()} title="Thema" />
			</NewPosting>
		</React.Fragment >
	)

}