import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/DialogForm.css';

export default function DialogForm(props) {

	const wad = props.wa.dialog;
	const wau = props.wa.utility;

	const classes = (typeof props.classes  === 'undefined') ? '' : props.classes
	const module = (typeof props.module  === 'undefined') ? "" : props.module;
	const sendTask = (typeof props.sendTask  === 'undefined') ? "" : props.sendTask;

	wad.init(props.id, {
		"setFocus": props.setFocus
	});

	return (

		<Dialog id={props.id} wa={props.wa} title={props.title} classes={'dialog-form ' + classes } closeIcon="1" >
			<Form 
				wa={props.wa} 
				wafId={props.wafId} 
				dialogId={props.id} 
				module={module}
				sendTask={sendTask}
				classes="webapp-form" 
				buttons={props.buttons}
				sendButton={wau.getProperty(props.sendButton, true)}
			>
				{props.children}
				<FormElement type="message"
					wa={props.wa}
					wafId={props.wafId}
					id="message"
					title="Hinweis"
				/>

			</Form>
		</Dialog>

	)
};
