import React from "react";
import Module from 'Components/Core/Module';
import DialogChangePassword from "./DialogChangePassword";
import Form from "Components/Core/Form/Form";
import FormElement from '../Core/Form/FormElement';
import Avatar from './Avatar';
import 'CSS/Member/Member.css';

export default function Settings(props) {

	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;

	const [memberLevel, setMemberLevel] = React.useState(4);
	const [memberName, setMemberName] = React.useState("");

	const wafId = props.id;
	const dialogId = 'dialog-change-password';

	waf.init(wafId, {
		"onAfterRead": onAfterRead,
		"onAfterSend": onAfterSend
	});

	wam.init(props.id, {
		"title": 'Einstellungen',
		"onClose": () => {
			waf.destroy(wafId);
			was.removeDialog(dialogId);
			waf.destroy(dialogId);
		}
	});

	React.useEffect(() => {
		return () => {
			// waf.destroy(wafId);
		}
	}, [])

	function changePassword() {
		wad[dialogId].show(() => { waf.focus(dialogId, 'old-password') });
	}

	function onAfterRead(data) {
		setMemberName(data.content.membername);
		setMemberLevel(data.content.memberlevel);
		waf.set(wafId, 'memberId', data.content.memberid, true);
		waf.set(wafId, 'name', data.content.name, true);
		waf.set(wafId, 'firstName', data.content.firstname, true);
		waf.set(wafId, 'mail', data.content.mail, true);
		waf.set(wafId, 'forumScroll', data.content.forumscroll, true);
		waf.set(wafId, 'forumHideImages', data.content.forumhideimages, true);
		waf.set(wafId, 'forumHideContent', data.content.forumhidecontent, true);
		waf.set(wafId, 'forumHideDiscussion', data.content.forumhidediscussion, true);
		waf.set(wafId, 'forumHideAvatar', data.content.forumhideavatar, true);
		waf.set(wafId, 'forumHideDate', data.content.forumhidedate, true);
		waf.set(wafId, 'forumMemberColored', data.content.forummembercolored, true);
		waf.set(wafId, 'forumFontBold', data.content.forumfontbold, true);
		waf.set(wafId, 'forumSendWithEnter', data.content.forumsendwithenter, true);
		waf.set(wafId, 'darkTheme', data.content.darktheme, true);
		waf.set(wafId, 'darkThemeVariant', data.content.darkthemevariant, true);
		waf.set(wafId, 'postingCount', data.content.postingcount, true);
		waf.set(wafId, 'lastLogin', data.content.lastlogin, true);
		waf.set(wafId, 'lastRequest', data.content.lastrequest, true);
	}

	function onAfterSend(data) {

		was.forumHideImages = (waf.get(wafId, 'forumHideImages') === 1) ? 'hide-images' : '';
		was.forumHideContent = (waf.get(wafId, 'forumHideContent') === 1) ? 'd-none' : 'd-block';
		was.forumHideDiscussion = (waf.get(wafId, 'forumHideDiscussion') === 1) ? 'd-none' : 'd-block';
		was.forumHideOT = (waf.get(wafId, 'forumHideContent') === 1) ? 'd-none' : 'd-inline';
		was.forumHideAvatar = (waf.get(wafId, 'forumHideAvatar') === 1) ? true : false;
		was.forumHideDate = (waf.get(wafId, 'forumHideDate') === 1) ? 'd-none' : 'd-inline';
		was.darkTheme = (waf.get(wafId, 'darkTheme') === 1) ? true : false;
		was.darkThemeVariant = (waf.get(wafId, 'darkThemeVariant') === 1) ? true : false;
	}

	const passwordButton = <button type="button" className="btn " onClick={changePassword} >Kennwort ändern</button>
	return (
		<Module id={props.id} wa={props.wa} title={props.title} display={props.display} displayTitle="d-block" closeButton="1">
			<div className="settings">
				<div className='d-flex'>
					<Avatar memberLevel={memberLevel} avatarText={memberName[0]} />
					<h2 className="">{memberName} ({was.memberLevelLabel[memberLevel]})</h2>
				</div>
				<Form wa={props.wa}
					wafId={wafId}
					module="Member"
					readTask="ReadMemberSettings"
					sendTask="SaveMemberSettings"
					focusElement="name"
					classes="member-form"
					buttons={passwordButton}>
					<fieldset className="form-group mb-2">
						<legend className="float-none w-auto">Allgemein</legend>
						<FormElement wa={props.wa} wafId={wafId} labelClasses="member-id" disabled={true} id="memberId" title="Mitglied Nr.:" />
						<FormElement wa={props.wa} wafId={wafId} id="name" title="Name" />
						<FormElement wa={props.wa} wafId={wafId} id="firstName" title="Vorname" />
						<FormElement wa={props.wa} wafId={wafId} id="mail" title="Mail" />
					</fieldset>
					<fieldset className="form-group mb-2">
						<legend className="float-none w-auto">Allgemein</legend>
						
					</fieldset>
					<fieldset className="mb-2">
						<legend>Forum</legend>
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="darkTheme" title="Dunkelmodus" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="darkThemeVariant" title="Alternatives Design (Nur Dunkelmodus)" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumScroll" title="Horizontal scrollen" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumHideImages" title="Bilder ausblenden" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumHideContent" title="Inhalte ausblenden" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumHideDiscussion" title="Diskussion ausblenden" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumHideAvatar" title="Avatar ausblenden" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumHideDate" title="Datum ausblenden" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumMemberColored" title="Name des Verfassers farbig anzeigen" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumFontBold" title="Fette Schrift" />
						<FormElement type="checkbox" wa={props.wa} wafId={wafId} id="forumSendWithEnter" title="Posting mit Enter senden" />
					</fieldset>
					<fieldset className="mb-2">
						<legend>Statistik</legend>
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="postingCount" title="Postings:" />
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="lastLogin" title="Letzte Anmeldung:" />
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="lastRequest" title="Letzter Zugriff:" />
					</fieldset>
				</Form>
				<DialogChangePassword id={dialogId} wa={props.wa} />
			</div>
		</Module>
	);

}

