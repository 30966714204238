import React from 'react';
import FormElement from "Components/Core/Form/FormElement";

export default function PostingElement(props) {

	const [newThread, setNewThread] = React.useState([""]);

	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;
	const wau = props.wa.utility;

	const classes = (typeof props.classes === 'undefined') ? null : props.classes;
	const children = (typeof props.children === 'undefined') ? null : props.children;
	const forumId = wam.getForumId();

	was.setNewThreadShort = setNewThread;

	function checkPaste(event) {
		let text = event.clipboardData.getData('text');
		if (wau.checkPaste(text, true, openNewThread)) {
			event.preventDefault();
		}
		else if (text.split('\n').length > 1) {
			waf.set(forumId + '-new-thread', 'posting', text);
			was.openNewThread()
			event.preventDefault();
		}
	}

	function openNewThread() {
		// let currentTitle = newThread;
		wam[forumId].showDialogNewThread();
		wam[forumId].setTab('postings'); // Funktion wird in tabs.js definiert
		wam.activate(forumId);
	}

	function newThreadChanged(value) {
		setNewThread(value);
	}

	return (
		<FormElement
			type="text"
			id={props.id}
			wafId={props.wafId}
			wa={props.wa}
			value={newThread}
			classes={classes}
			maxLength="500"
			placeholder="Neuer Beitrag"
			onPaste={checkPaste}
			onChange={event => newThreadChanged(event.target.value)}
			onFocus={props.onFocus}
			children={children}
			style={{ border: 'none' }}
			autocomplete="on"
		/>
	);

}
