import React from "react";
import Module from 'Components/Core/Module';
import Form from "Components/Core/Form/Form";
import FormElement from '../Core/Form/FormElement';
import 'CSS/SystemInfo.css';

export default function SystemInfo(props) {

	const waf = props.wa.form;	
	const wam = props.wa.module;	
	const wau = props.wa.utility;	
	
	const wafId = props.id;

	const renderCount = React.useRef(1);

	wam.init(props.id, {
		"title": 'System Informationen',
		"init": init,
		"onClose": onClose
	});

	React.useEffect(() => {
		if (renderCount.current === 1) {
			init();
		}
		renderCount.current++;
	})

	function init() {
		waf.set(wafId, 'system-info', wau.getStatus(), false);
	}

	function onClose() {
		waf.destroy(wafId);
	}
	return (
		<Module id={props.id} wa = {props.wa} title={props.title} display={props.display}  displayTitle="d-block" closeButton="1">
			
			<Form 	wa={props.wa} 
					wafId={wafId} 
					sendButtonTitle="Senden"
					enableSendButton={true}
					module="System"
					sendTask="SystemInfo"
					classes="system-info">	
					<FormElement 
						type="textarea" 
						wa={props.wa} 
						wafId={wafId} 
						id="system-info" 
						title="System-Informationen: " 
						readOnly={true}
					/>
			</Form>
		</Module>
	);

}

