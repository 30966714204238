import React from 'react';
import Dialog from '../Dialog';
import Listing from 'Components/Core/Listing';
import 'CSS/DialogSelectText.css';

export default function DialogSelectText(props) {

	const id = props.id + '-dialog';
	const refInput = React.useRef(null);
	const wafId = (typeof props.wafId === 'undefined') ? 'dialog-select-text' : props.wafId

	const wad = props.wa.dialog;
	const waf = props.wa.form;

	wad.init(id);
	wad[id].showSelection = showSelection;

	var lf;

	waf.init(wafId);
	waf[wafId][id] = {};
//	waf[wafId][id].reset = reset;

	function registerListing(listingFunctions) {
		lf = listingFunctions;
	}

	React.useEffect(() => {
		makeList();
	});

	React.useEffect(() => {
		if (typeof props.componentId !== 'undefined') {
			props.wa.system.addWatchKey(props.componentId, (event) => {
				if (event.keyCode === 27) {
					props.wa.form.focus('new-thread-short', 'posting');
				}
			});				
		}
		return () => {
            props.wa.system.delWatchKey(props.componentId);
        }
	}, [props, refInput]);

	function showSelection() {
		makeList();
		wad[id].show();
	}

	function select(selectId, selectName) {
		wad[id].hide();
		wad[id].setValue(selectId, selectName);
	}

	function makeList() {
		lf.reset();
		// addHeader();

		const data = (typeof props.setData !== 'undefined') ? props.setData() : props.data;
		if (data) {
			// memberCount = topics.length;
			data.forEach(
				function (item) {
					// console.log('Online Count: ' + onlineCount);
					let clickFunction = { "clickItem":null, "clickId":null, "clickFunction":() => {select(item.id, item.name)} }
					lf.addRow([item.name, item.description], clickFunction, (item.disabled===1) ? 'row-disabled' : '');
				}
			)
		}

		lf.makeListing();
	}

	/*
	function makeOptions() {

		const data = (typeof props.data === 'undefined') ? getDataFromServer() : props.data;

		const optionTags = [];
		let nextKey = 1;
		Object.entries(data).forEach(([key, value]) => {
//			if (refInput.current.value.length === 0 || value.name.toLowerCase().includes(refInput.current.value.toLowerCase())) {
			optionTags.push(<option key={nextKey} value={value.name} />);
			nextKey++;
			// console.log(`${key} ${value.name}`);
		});

		setOptions(
			<React.Fragment key={"form-select" + id}>
				{optionTags}
			</React.Fragment>
		);
	}
	*/
	return (
		<Dialog id={id} wa={props.wa} classes={`dialog-get-text ${props.classes}`} title={props.title} >
			<Listing id={id + '-listing'} wa={props.wa} registerListing={registerListing}  classes="select-text-list " />
		</Dialog>
	)
};
