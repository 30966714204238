import React from "react";

export default function WebappDialog(props) {

	const wa = props.wa;
	const renderCount = React.useRef(1);

	React.useEffect(() => {
		if (renderCount.current === 1) {
			// Zeiger auf Funktionen
			wa.dialog.addTag = addTag;
			wa.dialog.hideAll = hideAll;
			wa.dialog.toggleDisplay = toggleDisplay;
			wa.dialog.setDisplay = setDisplay;
			wa.dialog.init = init;
			props.childRendered();			
		}
		renderCount.current++;
	});

	function addTag(tag) {
		wa.dialog.tags[tag.props.id] = tag;
	}

	function setDisplay(id, visible) {
		wa.dialog[id].isVisible = visible;
	}

	function toggleDisplay(id) {
		wa.dialog[id].isVisible = ! wa.dialog[id].isVisible;
	}

	function init(id, settings) {
		if (typeof wa.dialog[id] === 'undefined') {
			wa.dialog[id] = {};
			wa.dialog[id].isVisible = false;
			wa.dialog[id].focusElement = null;
			wa.dialog[id].hide = () => {};
			wa.dialog[id].init = () => {};
			wa.dialog[id].onClose = () => {};
			wa.dialog[id].onAfterRender = () => {};
			wa.dialog[id].setTitle = () => {};
			wa.dialog[id].show = () => {};
		}
		if (typeof settings !== 'undefined') {
			wa.dialog[id] = Object.assign(wa.dialog[id], settings);
		}

	}

	function hideAll(currentDialog) {
        for (const key in wa.dialog) {
			if (typeof wa.dialog[key].hide != 'undefined' && key !== currentDialog) {
				wa.dialog[key].hide();
				wa.module[wa.module.current].onDialogClose(key);
			}
        }
    }

	return (
		<React.Fragment />
	);

}

