import React from 'react';
import NewPosting from 'Components/Forum/NewPosting';
import FormSelectText from 'Components/Core/Form/FormSelectText';
import DialogSelectText from 'Components/Core/Form/DialogSelectText';


export default function NewThread(props) {

	const wad = props.wa.dialog;
	const was = props.wa.system;
	const wau = props.wa.utility;

	const selectTopicId = props.moduleId + '-select-topic-new-thread';

	const [topicId, setTopicId] = React.useState(0);

	React.useEffect(() => {
		// wad[props.dialogId].postingRestore();
	}, []);


	function getTopicList() {
		const topicList = [{'id': 0, 'name': 'Kein Thema zuordnen'}].concat(was.topics);
		return topicList;			
	}

    return (
		<React.Fragment>
			<NewPosting 
				wa={props.wa}
				id={props.moduleId + '-thread'}
				newPostingFunctions = {props.newPostingFunctions}
				showDialogAnswer={props.showDialogAnswer}
				moduleId={props.moduleId} 
				forumId={props.forumId} 
				answerTo={props.answerTo} 
				topicId={topicId}
				titleText = {
					<FormSelectText
						id={selectTopicId}
						iconMode={false}
						wafId={props.moduleId + "-form-current-topic"}
						name="topicid"
						componentId={wau.getComponentId()}
						moduleId={props.id}
						wa={props.wa}
						disabled={(wad[props.dialogId].memberId === was.memberId || was.isAdmin()) ? false : true}
						title={(wad[props.dialogId].memberId === was.memberId || was.isAdmin()) ? "Diesem Beitrag ein Thema zuweisen." : "Kein Thema zugewiesen..."}
						alwaysShowSelection={false}
						afterSelect={(key, value) => {
							setTopicId(key);
						}}
						resetIcon={false}

					/>
				}
				dialogId={props.dialogId}
			/>
			<DialogSelectText id={selectTopicId} moduleId={props.id} wa={props.wa} data={getTopicList()} title="Thema" />
		</React.Fragment>
    )

}

