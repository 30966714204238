import React from 'react';
import 'CSS/Block.css';


export default function Block(props) {

	const wa = props.wa;
	const wab = wa.block;
	const classes = (typeof props.classes === 'undefined') ? "" : props.classes;
	const onBlur = (typeof props.onBlur === 'undefined') ? () => {} : props.onBlur;
	const [display, setDisplay] = React.useState((typeof props.display == 'undefined') ? 'd-none' : props.display);

	wab.init(props.id);
	wab[props.id]['hide'] = hide;
	wab[props.id]['show'] = show;
	wab[props.id]['toggleDisplay'] = toggleDisplay;

	function hide() {
		setDisplay('d-none');
	}

	function show() {
		setDisplay('d-box');
	}

	function toggleDisplay() {
		setDisplay(prev => (prev === 'd-none') ? 'd-box' : 'd-none');
	}


	return (
		<div key={props.id} id={props.id} className={`${classes} ${display} block`} onBlur={onBlur} >
			{props.children}
		</div>
	)

};

