import React from "react";

export default function WebappBlock(props) {

	const wa = props.wa;
	const renderCount = React.useRef(1);

	React.useEffect(() => {
		if (renderCount.current === 1) {
			// Zeiger auf Funktionen
			wa.block.addTag = addTag;
			wa.block.init = init;
			props.childRendered();			
		}
		renderCount.current++;
	});

	function addTag(tag) {
		wa.block.tags[tag.props.id] = tag;
	}

	function init(id, settings) {
		if (typeof wa.block[id] == 'undefined') {
			wa.block[id] = {};
			wa.block[id].hide = () => {};
			wa.block[id].init = () => {};
			wa.block[id].onAfterRender = () => {};
			wa.block[id].show = () => {};
			wa.block[id].toggleDisplay = () => {};
		}
		if (typeof settings != 'undefined') {
			wa.block[id] = Object.assign(wa.block[id], settings);
		}

	}



	return (
		<React.Fragment />
	);

}

