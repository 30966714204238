import React from 'react';
import MemberListBasis from 'Components/Member/MemberListBasis';
import 'CSS/App/NavigationLeft.css';
import 'CSS/Member/ActiveMemberList.css';


export default function NavigationLeft(props) {

	const wa = props.wa;

	return (
		<div id={props.id}  className="navigation-left">
			<MemberListBasis id="active-member-list" wa={wa} title="" active={true}  classes="active-member-list"/>
		</div>
	)
};

// 			<OpenWindowsLeft id="open-windows" wa={wa} title="Geöffnete Fenster"  classes="open-windows-left"/>

