import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Member/DialogChangePassword.css';

export default function DialogChangePassword(props) {

	const wad = props.wa.dialog;
	const wah = props.wa.hint;
	const waf = props.wa.form;
	const wau = props.wa.utility;
	const [error, setError] = React.useState(false);
	const wafId = props.id;

	waf.init(wafId, {
		"onFormChanged": onFormChanged,
		"onAfterSend": onAfterSend,
	});

	function onAfterSend(result) {
		if (result.returncode === 1) {
			wah.addHint('Kennwort geändert...');
			setError(false);
			wad[wafId].hide();
		}
		else {
			setError(true);
		}
	}

	function onFormChanged() {
		waf[wafId].enableSendButton(waf.get(wafId, 'old-password') > '' && validNewPassword());
	}

	function validNewPassword() {
		const oldPassword = waf.get(wafId, 'old-password');
		const newPassword = waf.get(wafId, 'new-password');
		return wau.validPassword(newPassword, oldPassword);
	}

	return (

		<Dialog id={wafId} wa={props.wa} title="Kennwort ändern" classes={'dialog-change-password'} closeIcon="1" >
			<Form
				wa={props.wa}
				wafId={wafId}
				classes="webapp-form"
				module="Member"
				sendTask="ChangePassword"
			>
				<FormElement wa={props.wa} wafId={wafId} id="old-password" title="Aktuelles Kennwort" />
				<FormElement wa={props.wa} wafId={wafId} id="new-password" title="Neues Kennwort" />
				<div id="hint">
					<div className={`form-group ${(error === true) ? 'd-block' : 'd-none'}`} >
						<label id="error" >Kennwort konnte nicht geändert werden.</label>
					</div>
					<div className={`form-group ${(error === false) ? 'd-block' : 'd-none'}`} >
						<label id="message" >8-20 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer.</label>
					</div>
				</div>
			</Form>
		</Dialog>

	)
};
