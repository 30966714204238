import React, { useEffect } from "react";

export default function WebappModule(props) {

	const wa = props.wa;
	const was = wa.system;
	const wau = wa.utility;
	const renderCount = React.useRef(1);

	useEffect(() => {
		if (renderCount.current === 1) {
			wa.module.tags = (typeof wa.module.tags === 'undefined') ? [] : wa.module.tags;
			wa.module.current = 'forum';							// Wird in der Funktion Init der Komponente App.js gesetzt 
			wa.module.activeWindows = [{}];							// Wird in der Funktion makeActiveWindowList gesetzt 

			wa.module.activate = activate;
			wa.module.addTag = addTag;
			wa.module.exists = exists;
			wa.module.hideAll = hideAll;
			wa.module.init = init;
			wa.module.getForumId = getForumId;
			wa.module.isForum = isForum;
			wa.module.makeActiveWindowList = makeActiveWindowList;
			wa.module.reloadForums = reloadForums;
			wa.module.showPosting = showPosting;
			props.childRendered();			
		}
		renderCount.current++;
	});

	function activate(id) {
		hideAll();
		if (!exists(id)) {
			init(id);
			wa.system.addModule(wa.module.tags[id]);
		}
		wa.module.current = id;

		// activeWindows Array neu erzeugen
		makeActiveWindowList()
		
		// Anzeige der offenen Fenster in der linken Navigation aktualisieren 
		wa.system.loadOpenWindows()
		wa.module[id].show();
	}

	function addTag(tag) {
		wa.module.tags[tag.props.id] = tag;
	}

	function exists(id) {
        return  ! (typeof wa.module[id] == 'undefined');        
    }

	function hideAll() {
        for (const key in wa.module) {
			if (typeof wa.module[key].hide != 'undefined') {
				wa.module[key].hide();
			}
        }
    }

	function reloadForums() {
        for (const key in wa.module) {
			if (isForum(key)) {
				wa.module[key].postings.load();
			}
        }
    }


	function init(id, settings) {
		if (typeof wa.module[id] == 'undefined') {
			wa.module[id] = {};
			wa.module[id].debug = () => {};
			wa.module[id].hide = () => {};
			wa.module[id].init = () => {};
			wa.module[id].onClose = () => {};
			wa.module[id].onDialogClose = () => {};
			wa.module[id].reload = () => {};
			wa.module[id].show = () => {};
			wa.module[id].scrollTop = () => {};
			wa.module[id].moduleType = 'other';
			wa.module[id].title = 'Module';
			wa.module[id].clickStack = [];				// Stapel zum Speichern von Click-Handlern der jeweiligen Module
			wa.module[id].keyStack = [];				// Stapel zum Speichern von Key-Handlern der jeweiligen Module
			wa.module[id].quote = '';
			wa.module[id].quotePosting = 0;
			wa.module[id].lastPostingNo = 0;
			wa.module[id].currentPostingNo = 0;
		}

		if (typeof settings != 'undefined') {
			wa.module[id] = Object.assign(wa.module[id], settings);
		}
/*
		defaultValues = (typeof defaultValues == 'undefined') ? {} : defaultValues;
		Object.entries(defaultValues).forEach(([key, value]) => {
			wa.module[id][key] = value;
		});
		wa.module.current = id;
*/
	}

	function makeActiveWindowList() {
		wa.module.activeWindows = [];
		// "id":"forum", "text":"Forum"
		for (const [key, value] of Object.entries(wa.module)) {
			if (typeof value.moduleType !== 'undefined') {
				if (value.moduleType !== 'system') {
					wa.module.activeWindows.push({"id":key, "name":value.title + makeUnreadHint(wau.getConversationalPartner(key))});
				}
			}
		}
		wa.module.activeWindows.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
		return wa.module.activeWindows;
	}

	function makeUnreadHint(memberId) {
		let hint = '';
		for (const key in was.postingsUnread) {
			if (wau.getConversationalPartner(key) === memberId) {
				hint = ' (' + was.postingsUnread[key] + ')';
				break;
			}
		}
		return hint;
	}

	function showPosting(postingNo) {
		wa.dialog['ok'].setTitle('Zitiere Posting');
		wa.form.set('ok', 'ok-text', 'Füge Posting Nr. ' + postingNo + ' ein...');
		wa.dialog['ok'].show();
		return false;
	}

    function getForumId() {
        var forumId = wa.module.current;   // Das aktuelle Modul ist ein Forum
        if (wa.module.current === '') {
            forumId = 'forum';        // Es gibt kein aktuelles Modul
        } 
        else {
            if (wa.module[forumId].moduleType !== 'forum') {
                forumId = 'forum';        // Das aktuelle Modul ist kein Forum
            }
        }
        // console.log('ForumId: ' + forumId);
        return forumId;
    }


	function isForum(forumId = wa.module.current) {
		// console.log('isForum: ' + forumId);
		return (wa.module[forumId].moduleType === 'forum');
	}


	return (
		<React.Fragment />
	);

}

