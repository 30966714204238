import React from 'react';
import PostingElement from './PostingElement';

export default function NewThreadShort(props) {

	const $ = window.$;
	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;
	const wafId = "new-thread-short";
	
	React.useEffect(() => {
		props.wa.system.addWatchKey(wafId, (event) => {
			if (event.keyCode === 27) {
				waf.set('new-thread-short', 'new-posting-short', '');
			}
		});
	});

	React.useEffect(() => {

		// if (! was.isMobileDevice()) waf.focus('new-thread-short', 'posting');

		return () => {
			props.wa.system.delWatchKey(wafId);
		}

	}, [props]);

	if (was.windowWidth > 1000) {
		$('#new-posting-short').trigger("focus");
	}

	function saveNewThread(event) {
		event.preventDefault();
		var forumId;
		if (wam.isForum()) {
			forumId = wam.current;
		}
		else {
			forumId = 'forum'
			wam.activate(forumId);
			// was.activateModule(forumId);
		}
		const newThread = waf.get(wafId, 'new-posting-short');
		if (newThread.trim() > '') {
			wam[forumId].closeDialogs();
			// console.log('Save new Posting: ' + newThread);
			waf.set(forumId + '-new-thread', 'posting', newThread);
			waf[forumId + '-new-thread'].send();
			waf.select(wafId, 'new-posting-short');
		}
	}

	/*
	async function newThreadFromClipboard() {
		const cb = await navigator.clipboard.read();
		const type = cb[0].types[0].substring(0, 4).toLowerCase();
		if (type === 'text') {
			const text = await navigator.clipboard.readText();
			pasteText(text);
		}
		if (type === 'image') {
			const text = await navigator.clipboard.readText();
			pasteText(text);
		}
	}
	*/

	return (
		<div id="new-thread-short" className={` new-thread-short `}>
			<form className={`form-new-thread-short me-2 ${props.newThreadClass}`} onSubmit={e => saveNewThread(e)}>
					<PostingElement 
						id="new-posting-short" 
						wa={props.wa}
						wafId={wafId}
						classes="new-posting new-posting-short" 
						maxLength="500" 
						placeholder="Neuer Beitrag"
					/>
			</form>
		</div>
	);


}
