import React from "react";
import Module from 'Components/Core/Module';
import 'CSS/Admin/PhpInfo.css';

export default function PhpInfo(props) {

	const url = 'https://' + document.location.hostname + '/Backend/webapp.php?module=Admin&task=PhpInfo';

	return (
		<Module id={props.id} wa={props.wa} display={props.display} title={props.title}>
			<iframe title="phpinfo" className="php-info" src={url}>
			</iframe>
		</Module>
	);

}
