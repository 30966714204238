import React from "react";

export default function WebappLayout(props) {

	const wa = props.wa;
	const wal = wa.layout;
	const was = wa.system;
	var style = {};
	wal.style = style;
	wal.setStyle = setStyle;

	const renderCount = React.useRef(1);

	React.useEffect(() => {
		setStyle();
	});

	React.useEffect(() => {
		if (renderCount.current === 1) {
			props.childRendered(props.id);
		}
		renderCount.current++;
	}, [props]);

	function setStyle() {
		const style = {};
		const contentColor = "#2f343d";
		const mainColor = "#5f8c6c";
		// const mainColor = "#009EE0"; // afd
		const loginBackgroundColor="#475052";

		style["--login-color"] = "#c1c7d0";
		style["--login-bg-color"] = loginBackgroundColor;
		style["--login-button-color"] = "#efefef";
		style["--login-button-bg-color"] = loginBackgroundColor;
		style["--login-logo-bg-color"] = "#f2f2f2";
		style["--login-logo-border-color"] = "#8c999c";
		style["--login-dialog-color"] = "#222222";
		style["--login-dialog-bg-color"] = "#f3f2ef";
		style["--login-dialog-border-color"] = "#888888";
		style["--login-dialog-shadow-color"] = "#666666";

		if (!was.darkTheme) {
			const borderColor = "#ced4da";
			const navBorderColor = "#5f5f5f";
			const dialogBackgroundColor = "#fff";
			style["--button-bg-color"] = mainColor;
			style["--button-color"] = "#fdfdfd";
			style["--checkbox-color"] = "#c1c7d0";
			style["--content-bg-color"] = "#fff";
			style["--content-bg-color-disabled"] = "#fcfcfc";
			style["--content-border-color"] = borderColor;
			style["--content-color"] = contentColor;
			style["--content-color-disabled"] = "#000";
			style["--content-color-error"] = "#dc3545";
			style["--content-color-hint"] = "#43624c";
			style["--content-disabled-color"] = "#000";
			style["--dialog-color"] = mainColor;
			style["--dialog-bg-color"] = dialogBackgroundColor;
			style["--dialog-border-color"] = dialogBackgroundColor;
			style["--dialog-title-color"] = "#444";
			style["--forum-bg-color"] = dialogBackgroundColor;
			style["--forum-color"] = contentColor;
			style["--forum-link-color"] = mainColor;
			style["--forum-quote-color"] = "#ebecef";
			style["--hint-bg-color"] = "#f8f7f5";
			style["--hint-color"] = mainColor;
			style["--icon-color"] = mainColor;
			style["--icon-color-clicked"] = "#c11734";
			style["--listing-color"] = "#444";
			style["--listing-bg-color"] = "#fefefe";
			style["--listing-border-color"] = mainColor;
			style["--listing-title-color"] = dialogBackgroundColor;
			style["--listing-title-bg-color"] = mainColor;
			style["--listing-nav-color"] = "#dfdfdf";
			style["--listing-nav-bg-color"] = "#3c4345";
			style["--listing-nav-border-color"] = navBorderColor;
			style["--main-bg-color"] = mainColor;
			style["--main-color"] = "#000";
			style["--module-title-border"] = mainColor;
			style["--nav-bg-color"] = mainColor;
			style["--nav-border-color"] = navBorderColor;
			style["--nav-color"] = "#000";
			style["--nav-icon-color"] = dialogBackgroundColor;
			style["--new-posting-short-border"] = "1px solid " + borderColor;
			style["--placeholder-color"] = mainColor;
			style["--shadow-color"] = "#888";
			style["--tabs-bg-color"] = dialogBackgroundColor;
			style["--tabs-border-color"] = mainColor;
			style["--tabs-color"] = mainColor;
			style["--tabs-selected-color"] = "#efefef";
		}
		else {
			const backgroundColor = (was.darkThemeVariant) ? "#2d323a" : "#262626";
			const borderColor = "#3f4653"
			const contentColor = "#c1c7d0";
			const listingColor = "#dfdfdf";
			style["--button-bg-color"] = mainColor;
			style["--button-color"] = "#fdfdfd";
			style["--checkbox-color"] = contentColor;
			style["--content-bg-color"] = backgroundColor;
			style["--content-bg-color-disabled"] = backgroundColor;
			style["--content-border-color"] = borderColor;
			style["--content-color"] = contentColor;
			style["--content-color-disabled"] = backgroundColor;
			style["--content-color-error"] = "#dc3545";
			style["--content-color-hint"] = "#43624c";
			style["--content-disabled-color"] = contentColor;
			style["--dialog-color"] = contentColor;
			style["--dialog-bg-color"] = backgroundColor;
			style["--dialog-border-color"] = borderColor;
			style["--dialog-title-color"] = contentColor;
			style["--forum-bg-color"] = backgroundColor;
			style["--forum-color"] = contentColor;
			style["--forum-link-color"] = mainColor;
			style["--forum-quote-color"] = "#333842";
			style["--hint-bg-color"] = "#f8f7f5";
			style["--hint-color"] = mainColor;
			style["--icon-color"] = contentColor;
			style["--icon-color-clicked"] = "#c11734";
			style["--listing-color"] = listingColor;
			style["--listing-bg-color"] = (was.darkThemeVariant) ? "#2d323a" : "#262626";
			style["--listing-border-color"] = mainColor;
			style["--listing-title-color"] = "#fff";
			style["--listing-title-bg-color"] = mainColor;
			style["--listing-nav-color"] = listingColor;
			style["--listing-nav-bg-color"] = (was.darkThemeVariant) ? "#262626" : "#2d323a";
			style["--listing-nav-border-color"] = borderColor;
			style["--main-bg-color"] = mainColor;
			style["--main-color"] = "#fff";
			style["--module-title-border"] = borderColor;
			style["--nav-border-color"] = borderColor;
			style["--nav-bg-color"] = backgroundColor;
			style["--nav-color"] = "#fff";
			style["--nav-icon-color"] = contentColor;
			style["--new-posting-short-border"] = "1px solid " + mainColor;
			style["--placeholder-color"] = contentColor;
			style["--shadow-color"] = "#272b32";
			style["--tabs-bg-color"] = backgroundColor;
			style["--tabs-border-color"] = borderColor;
			style["--tabs-color"] = contentColor;
			style["--tabs-selected-color"] = "#efefef";
		}
		style["--mobile-width"] = "1024px";
		wal.style = style;
	}	

	return (
		<React.Fragment />
	);

}

