import React from "react";
import axios from "axios";

export default function WebappForm(props) {

	const wa = props.wa;
	const wah = wa.hint;
	const wau = wa.utility;
	const renderCount = React.useRef(1);

	// Zeiger auf Funktionen
	wa.form.checkChanged = checkChanged;
	wa.form.destroy = destroy;
	wa.form.disable = disable;
	wa.form.enable = enable;
	wa.form.focus = focus;
	wa.form.get = get;
	wa.form.getKey = getKey;
	wa.form.getValid = getValid;
	wa.form.hide = hide;
	wa.form.init = init;
	wa.form.initField = initField;
	wa.form.insert = insert;
	wa.form.isField = isField;
	wa.form.isReadOnly = isReadOnly;
	wa.form.mandatory = mandatory;
	wa.form.mandatoryOk = mandatoryOk;
	wa.form.select = select;
	wa.form.send = send;
	wa.form.set = set;
	wa.form.setReadOnly = setReadOnly;
	wa.form.setValid = setValid;
	wa.form.setAddSend = setAddSend;
	wa.form.setCursorPos = setCursorPos;
	wa.form.setKey = setKey;
	wa.form.setSource = setSource;
	wa.form.show = show;

	React.useEffect(() => {
		if (renderCount.current === 1) {
			props.childRendered();
		}
		renderCount.current++;
	});

	function destroy(wafId) {
		console.log('Destroy Webapp Form: ' + wafId);
		delete wa.form[wafId];
	}

	function init(wafId, settings) {
		if (typeof wa.form[wafId] === 'undefined') {
			// console.log('Init Webapp Form: ' + wafId);
			wa.form[wafId] = {};
			wa.form[wafId].onFormChanged = () => { };
			wa.form[wafId].onRead = () => { };
			wa.form[wafId].onAfterRead = () => { };
			wa.form[wafId].onBeforeRead = () => { };
			wa.form[wafId].onAfterSend = () => { };
			wa.form[wafId].onBeforeSend = () => { };
			wa.form[wafId].onSave = () => { };
			wa.form[wafId].onAfterSave = () => { };
			wa.form[wafId].onBeforeSave = () => { };
			wa.form[wafId].send = () => { };
			wa.form[wafId].elements = {};								// Enthält sämtliche Felder des Formulars
			wa.form[wafId].props = {};
			wa.form[wafId].addSend = {};
		}
		if (typeof settings !== 'undefined') {
			wa.form[wafId] = Object.assign({}, wa.form[wafId], settings);
		}
	}

	function initField(wafId, fieldId, reference, defaultValue, fieldName) {
		init(wafId);
		if (typeof wa.form[wafId].elements[fieldId] === 'undefined') {
			wa.form[wafId].elements[fieldId] = {
				"reference": reference,
				"valid": true,
				"initial": "",
				"mandatory": false
			};
			if (typeof defaultValue != 'undefined') {
				set(wafId, fieldId, defaultValue, true);
			}
			if (typeof fieldName != 'undefined') {
				wa.form[wafId].elements[fieldId].name = fieldName;					// Falls gesetzt, wird in "prepare" dieser Wert als Feldname anstelle der ID verwendet
			}
		}
	}

	function isField(wafId, fieldId) {
		return (typeof wa.form[wafId] !== 'undefined' && typeof wa.form[wafId].elements[fieldId] !== 'undefined');
	}
	function get(wafId, fieldId) {
		initField(wafId, fieldId);
		if (wa.form[wafId].elements[fieldId].reference.current.type === 'checkbox') {
			return ((wa.form[wafId].elements[fieldId].reference.current.checked) ? 1 : 0);
		}
		else {
			return wa.form[wafId].elements[fieldId].reference.current.value;
		}
	}

	function getKey(wafId, fieldId, key) {
		initField(wafId, fieldId);
		return wa.form[wafId].elements[fieldId].key;					// Für Select-Felder. Wenn definiert, wird dieser Wert anstelle des Values an den Server gesendet
	}

	function getValid(wafId, fieldId) {
		return wa.form[wafId].elements[fieldId].valid;
	}


	function set(wafId, fieldId, value, initial) {
		const setInitial = (typeof initial === 'undefined') ? false : initial;
		initField(wafId, fieldId);
		const formElement = wa.form[wafId].elements[fieldId].reference.current;
		if (formElement.type === 'checkbox') {
			formElement.checked = value;
		}
		else {
			if (formElement.tagName === 'DIV') {
				formElement.innerHTML = value;
			}
			else {
				formElement.value = value;
			}
		}
		if (setInitial) {
			wa.form[wafId].elements[fieldId].initial = value;
		}
		else {
			wa.form[wafId].onFormChanged();
		}
	}

	function setValid(wafId, fieldId, valid) {
		wa.form[wafId].elements[fieldId].valid = valid;
	}

	function setKey(wafId, fieldId, key) {
		wa.form[wafId].elements[fieldId].key = key;					// Für Select-Felder. Wenn definiert, wird dieser Wert anstelle des Values an den Server gesendet
	}

	function checkChanged(wafId) {
		let changed = false;
		let formElement;
		if (mandatoryOk(wafId)) {
			for (const key in wa.form[wafId].elements) {
				formElement = wa.form[wafId].elements[key].reference.current;
				if (formElement.type === 'checkbox') {
					if (wa.form[wafId].elements[key].initial !== formElement.checked) {
						changed=true;
					}
				}
				if (formElement.type === 'text' || formElement.type === 'textarea') {
					if (get(wafId, key) !== wa.form[wafId].elements[key].initial) {
						changed=true;
					}
				}
			}
		}
		return changed;
	}

	function send(wafId, url, handleResult, handleError) {
		handleError = (typeof handleError === 'undefined') ? () => {} : handleError;
		wa.form[wafId].onBeforeSend();
		// console.log(prepare(wafId));
		axios.post(wau.getUrl(url), prepare(wafId))
			.then(result => {
				handleResult(result);
			})  
			.catch(function (error) {
				wah.addHint('Error in Axios Post: ' + url, false);
				handleError();
			})
	}

	function focus(wafId, fieldId) {
		// console.log('WafId:' + wafId + ':' + wa.form[wafId].elements[fieldId].reference.current.id);
		wa.form[wafId].elements[fieldId].reference.current.focus();
	}
	
	function setCursorPos(wafId, fieldId, start, end) {
		wa.form[wafId].elements[fieldId].reference.current.setSelectionRange(start, end);
		//wa.form[wafId].elements[fieldId].reference.setCursorPosition(1);

	}

	function enable(wafId, fieldId) {
		wa.form[wafId].elements[fieldId].reference.current.disabled = false;
	}

	function disable(wafId, fieldId) {
		wa.form[wafId].elements[fieldId].reference.current.disabled = true;
	}

	function isReadOnly(wafId, fieldId) {
		return wa.form[wafId].elements[fieldId].reference.current.readOnly;
	}

	function setReadOnly(wafId, fieldId, set) {
		wa.form[wafId].elements[fieldId].reference.current.readOnly = set;
	}

	function show(wafId, fieldId) {
		wa.form[wafId].elements[fieldId].reference.current.hidden = false;
	}

	function hide(wafId, fieldId) {
		wa.form[wafId].elements[fieldId].reference.current.hidden = true;
	}

	function setAddSend(wafId, fieldId, value) {
		wa.form[wafId].addSend[fieldId] = value; 
	}

	function setSource(wafId, fieldId, source) {
		const formElement = wa.form[wafId].elements[fieldId].reference.current;
		formElement.src = source;
	}

	function mandatory(wafId, fieldId, mandatory) {
		wa.form[wafId].elements[fieldId].mandatory = mandatory;
	}

	function mandatoryOk(wafId) {
		let ok = true;
		let ref;
		for (const key in wa.form[wafId].elements) {
			ref = wa.form[wafId].elements[key].reference.current;
			if (wa.form[wafId].elements[key].mandatory) {
				if (get(wafId, key).trim() === '') {
					if (! ref.className.includes('mandatory')) {
						ref.className = ref.className + ' mandatory';
					}
					ok = false;
				}
				else {
					ref.className = ref.className.replace(" mandatory", "");
				}
			}
		}
		return ok;
	}

	function select(wafId, fieldId) {
		wa.form[wafId].elements[fieldId].reference.current.select();
	}


	function insert(wafId, fieldId, value) {
		const curPos = wa.form[wafId].elements[fieldId].reference.current.selectionStart;
		const curVal = wa.form.get(wafId,fieldId);
		const leftValue = curVal.substring(0, curPos);
		const rightValue = curVal.substring(curPos);
		wa.form.set(wafId, fieldId,leftValue + value + rightValue);
		wa.form[wafId].elements[fieldId].reference.current.selectionStart = curPos;
		wa.form[wafId].elements[fieldId].reference.current.selectionEnd = curPos;
	}

	function prepare(wafId) {
		var fields = {};
		var fieldName, formElement;
		for (const key in wa.form[wafId].elements) {
			if (typeof wa.form[wafId].elements[key].reference !== 'undefined') {
				if (typeof wa.form[wafId].elements[key].name === 'undefined') {
					fieldName = key;
				}
				else {
					fieldName = wa.form[wafId].elements[key].name;
				}
				formElement = wa.form[wafId].elements[key].reference.current;
				if (formElement.type === 'checkbox') {
					fields[fieldName] = (formElement.checked) ? "1" : "0";
				}
				else {
					fields[fieldName] = formElement.value;
				}

				// Falls ein Key gesetzt ist, wird dieser anstatt des Field-Values zur Speicherung verwendet
				// Implementiert für Auswahlfelder (FormSelect Komponente)
				if (typeof wa.form[wafId].elements[key].key !== 'undefined') {
					fields[fieldName] = wa.form[wafId].elements[key].key;
				}
				
			}
		}
		Object.entries(wa.form[wafId].addSend).forEach(([key, value]) => {
			fields[key] = value;
		});
		// console.log(fields);
		return fields;
	}

	return (
		<React.Fragment />
	);

}

