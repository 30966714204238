import React from 'react';
import Block from 'Components/Core/Block';
import Listing from 'Components/Core/Listing';
import 'CSS/App/Menu.css';

export default function Menu(props) {

	var lf = {};

	const wab = props.wa.block;
	const wan = props.wa.navigation;
	const was = props.wa.system;

	wan.init(props.id);
	wan[props.id].show = show;
	wan[props.id].hide = hide;
	wan[props.id].makeMenu = makeListing;

    React.useEffect(() => {
        makeListing();
    });
    
	function registerListing(listingFunctions) {
		lf = listingFunctions;
	}


	function hide() {
		wab[props.id].hide();
		wab.modal.hide();
	}

	function show() {
		wab[props.id].show();
		wab.modal.show();
	}

    function makeListing() {
        lf.reset();
		const header = [];
        if (props.parentId === '') {
            header.push(<i key="header-left" className="bi bi-house listing-icon" onClick={e => was.activateModule('forum')}></i>);
        }
        else {
            header.push(<i key="header-left" className="bi bi-arrow-left listing-icon" onClick={e => was.activateNavigation(props.parentId)}></i>);
        }
        header.push(<span key="header-title" >{props.title}</span>);
        header.push(<i key="header-right" className="bi bi-x-lg listing-icon" onClick={e => wan[props.id].hide()}></i>);
        lf.addHeader(header);
        var tagTitle;
        for (const [key, value] of Object.entries(props.menuSource)) {
            tagTitle = <span key={key} className="menu-item" >{value.title}</span>;
            lf.addRow([tagTitle], {"clickItem":[], "clickFunction": props.menuClick, "clickId":value});
        }
        lf.makeListing();

    }

    return (
        <Block id={props.id} wa={props.wa} display="d-none" classes="no-modal" >
			<Listing id={props.id + '-listing'} wa={props.wa} registerListing={registerListing} classes="listing webapp-menu listing-menu " />
		</Block>
    )

}
