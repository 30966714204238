import React from "react";
import Block from "Components/Core/Block";
import 'CSS/Modal.css';
export default function Modal(props) {

	const wa = props.wa;

	wa.system.setModal = setModal;

	function setModal(set) {
		if (set) {
			wa.block[props.id].show();
		}
		else {
			wa.block[props.id].hide();
		}
	}

    return (
        <Block id={props.id} wa={wa} classes={`webapp-modal `} />
    );


}
