import React from "react";

export default function WebappHint(props) {

	const wa = props.wa;
	const wah = wa.hint;
	const was = wa.system;
	const renderCount = React.useRef(1);
	const hints = React.useRef({});
	const hintCloseCounter = React.useRef([]);
	const hintIntervalHandle = React.useRef([]);
	const hintId = React.useRef(1);

	// Zeiger auf Funktionen
	wah.addHint = addHint;
	wah.removeHint = removeHint;
	wah.isVisible = isVisible;

	React.useEffect(() => {
		if (renderCount.current === 1) {
			props.childRendered();
		}
		renderCount.current++;
	});

	function addHint(hint, autoClose = true) {
		const currentId = hintId.current;
		hints.current[currentId] = makeHint(hint, autoClose);
		hintId.current++;
		return currentId;
	}

	function removeHint(event, id) {
		if (event !== null) event.preventDefault();
		hints.current[id] = null;
		was.removeHint('hint-' + id);
	}
	function makeHint(hint, autoClose = true) {
		try {
			const currentId = hintId.current;
			const hintText = <div className="hint-text">{hint}</div>;
			let hintClose;
			hintCloseCounter.current[currentId] = 3;

			if (autoClose) {
				hintClose = makeAutoCloseTag(currentId);
				hintIntervalHandle.current[currentId] = setInterval(() => {
					hintCloseCounter.current[currentId]--;
					if (hintCloseCounter.current[currentId] === 0) {
						clearInterval(hintIntervalHandle.current[currentId]);
						removeHint(null, currentId);
					}
					else {
						was.removeHint('hint-' + currentId);
						const hintClose = makeAutoCloseTag(currentId);
						const hintTag = <div id={'hint-' + currentId} className="hint">
							{hintClose}
							{hintText}
						</div>;
						was.addHint(hintTag);
					}
				}, 1000);
			}
			else {
				hintClose = <span className="hint-close bi bi-x" onClick={e => removeHint(e, currentId)} />;
			}
			const hintTag = <div id={'hint-' + currentId} className="hint">
				{hintClose}
				{hintText}
			</div>;
			was.addHint(hintTag);
			return hintId.current;
		}
		catch (err) {
			was.reportError(err);
		};
	}

	function makeAutoCloseTag(currentId) {
		return <span>
			<span className={`hint-close bi ${'bi-' + hintCloseCounter.current[currentId] + '-square'} `} onClick={e => removeHint(e, currentId)} />
		</span>;
	}

	function isVisible(hintId) {
		if (typeof hints.current[hintId] != 'undefined' && hints.current[hintId] !== null) {
			return true;
		}
		else {
			return false;
		}
	}

	return (
		<React.Fragment />
	);

}

