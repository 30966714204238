import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Forum/DialogPreview.css';

export default function DialogPreview(props) {

	const wad = props.wa.dialog;
	const waf = props.wa.form;

	waf.init(props.id);
	wad.init(props.id);

	return (

		<Dialog id={props.id} wa={props.wa} title={props.title} classes="dialog-preview" closeIcon={true}  >
			<Form 	wa={props.wa} 
					wafId={'preview-posting-' + props.id}
					dialogId={props.id} 
					classes="webapp-form" 
					sendButtonTitle="Beitrag erstellen" 
					enableSendButton={true} 
					onSubmit={props.onSubmit}
			>
				<FormElement type="div" wa={props.wa} wafId={props.id} readOnly={true} id="preview-posting" title="Text" />
			</Form>
		</Dialog>

	)
};
