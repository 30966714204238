import React from 'react';
import Dialog from "Components/Core/Dialog";

export default function DialogYesNo(props) {

	var wad = props.wa.dialog;
	wad.init(props.id);

	function handleClose(result) {
		if (typeof result === 'undefined') {
			result = 0;
		}
		wad['yesno'].hide();
		wad['yesno'].onClose(result);
	}

	return (

		<Dialog id={props.id} wa={props.wa} title={props.title} classes="dialog-yesno" closeIcon="1" modal={true}>
			<div className='div-button'>
				<button className="btn " type="button" onClick={event => handleClose(1)} >Ja</button>
				<button className="btn " type="button" onClick={event => handleClose(0)} >Nein</button>
			</div>
		</Dialog>

	)
};
