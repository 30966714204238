import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Member/DialogAddMember.css';

export default function DialogAddMember(props) {

	const wab = props.wa.block;
	const wad = props.wa.dialog;
	const wah = props.wa.hint;
	const waf = props.wa.form;
	const wafId = props.wafId;
	const [error, setError] = React.useState(false);
	const [message, setMessage] = React.useState('');

	wad.init(props.id, {
		"onClose": onClose,
	});

	waf.init(wafId, {
		"onAfterSend": onAfterSend,
		"onClose": onClose,
	});

	function onAfterSend(result) {
		if (result.returncode === 1) {
			wah.addHint('Benutzer angelegt...');
			setError(false);
			setMessage(<div>Benuter angelegt.<br/> <div>{'Temporäres Kennwort: '}<br/> {result.content.password} </div></div> );
			wab['member-listing'].reload();
		}
		else {
			setError(true);
		}
	}

	function onClose() {
		setMessage('');
		setError(false);
		waf.set(wafId, 'member-name', '');
	}

	return (

		<Dialog id={props.id} wa={props.wa} title="Neues Mitglied anlegen" classes={'dialog-add-member'} closeIcon="1" >
			<div className={`form-group ${(message === '') ? 'd-block' : 'd-none'}`}>
				<Form
					wa={props.wa}
					wafId={wafId}
					classes="webapp-form "
					module="Member"
					sendTask="AddMember"
				>
					<FormElement wa={props.wa} wafId={wafId} id="member-name" title="Pseudonym"  />
					<div id="hint">
						<div className={`form-group ${(error === true) ? 'd-block' : 'd-none'}`} >
							<label id="error" >Benutzer konnte nicht angelegt werden.</label>
						</div>
					</div>

				</Form>
			</div>

			<div className={`form-group ${(message !== '') ? 'd-block' : 'd-none'}`} >
						<label id="message" >{message}</label>
			</div>

		</Dialog>

	)
};
