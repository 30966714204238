import React from 'react';
import 'CSS/Member/Avatar.css';

export default function Avatar(props) {
	const small = (typeof props.small === 'undefined') ? false : props.small; 
	let handleClick = () => {};
	let handleId = 0;
    let avatarColor = 'bg-primary';
    let avatarClass, avatarContentClass;
	let avatarTag = <></>;
	let memberTag = <></>;
    
	if (typeof props.handleClick != 'undefined') {
		handleClick = props.handleClick;
		handleId = props.handleId;
	}

    if (small) {
        avatarClass = 'webapp-avatar-small';
        avatarContentClass = 'webapp-avatar-content-small';
    }
	else {
        avatarClass = 'webapp-avatar-large';
        avatarContentClass = 'webapp-avatar-content-large';
	}

	if (typeof props.memberName !== 'undefined') {
		memberTag = <span className='avatar-name'>{props.memberName}</span>
    }

	avatarTag = <span className='avatar-text'>{props.avatarText}</span>;
    switch (parseInt(props.memberLevel)) {
        case 1:
          avatarColor = 'bg-primary';
          break;
        case 2:
          avatarColor = 'bg-success';
          break;
        default:
          avatarColor = 'bg-orange';
    }

    return (
		<div className={`webapp-avatar ${avatarClass}`}>            
			<span onClick={e => handleClick(handleId)} className={`rounded text-light webapp-avatar-content ${avatarContentClass} ${avatarColor}`}>
				{avatarTag}
			</span>
			{memberTag}
			{props.children}
		</div>
    );


}
