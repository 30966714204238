import React from 'react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import 'CSS/Forum/FormDatePicker.css';
import de from 'date-fns/locale/de';

export default function FormDatePicker(props) {

	const [selected, setSelected] = React.useState(new Date());

	function dateSelected(date) {
		if (typeof date !== 'undefined') {
			setSelected(date);
			props.dateSelected(format(date, 'dd.MM.yyyy'));
		}
	}
	const element = 
		<DayPicker
			mode="single"
			locale={de}
			captionLayout="dropdown" 
			fromYear={1980} 
			toYear={format(new Date(), 'yyyy')}
			selected={selected}
			onSelect={dateSelected}
		/>

	return (
		<React.Fragment key={"form-element" + props.id}>
			{element}
		</React.Fragment>
	);

};
