import React from "react";
import Posting from "./Posting";
import 'CSS/Forum/PostingsList.css';

export default function PostingList(props) {

	const [postingSource, setPostingSource] = React.useState([]);       // Enthält die Posting-Daten vom Server. Diese werden dann in der Funktion makePostings aufbereitet. 

	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;
	const wau = props.wa.utility;
	const onAfterReadPostings = (typeof props.onAfterReadPostings === 'undefined') ? () => {} : props.onAfterReadPostings;

	let addUrl = (typeof props.addUrl === 'undefined') ? '' : props.addUrl;
	let postingsCount = 0;
	let wafId = props.dialogId;

	React.useEffect(() => {
		// wam[props.moduleId][props.functions.loadPostings] = getPostings;
		props.onRendered({
			"addSearchUrl": setAddUrl,
			"searchPostings": getPostings,
			"reset": reset,
		})
	});


	waf.init(wafId, {
		'setPostingSource':setPostingSource
	});

	// wam.init(props.moduleId);

	/*
	if (typeof props.functions != 'undefined') {
		if (typeof props.functions.setAddUrl !== 'undefined') wam[props.moduleId][props.functions.setAddUrl] = setAddUrl; 
		if (typeof props.functions === 'function') {
			props.functions(setAddUrl, getPostings);
		}
	}
	*/

	function setAddUrl(add) {
		addUrl = add
	}

	function getPostings() {
		// console.log(addUrl);
		let url = '/Backend/webapp.php?module=' + props.module + '&task=' + props.task + '&forumId=' + props.forumId + addUrl;
		was.axGet(url, handleGetPostings);
	}

	function handleGetPostings(result) {
		if (result.data['returncode'] === 1) {
			setPostingSource(result.data['content']);
			onAfterReadPostings(result);
		}
		else {
			console.error('Error reading result');
		}
	}

	function reset() {
		setPostingSource([]);
	}
	
	function showThread(event, threadNo, postingNo) {
		// console.log(event);
		wam[props.moduleId].topic.setSelectThread(threadNo);
		wam[props.moduleId].topic.load(postingNo);
	}

	function makePostings(source) {
		let postingTags = [];
		let title = null;
		postingsCount = 0;
		source.forEach(
			function (item, index) {
				postingsCount++;
				title = 
					<span 
						key={'title-' + item.postingno} 
						id={'title-' + item.postingno}
						dangerouslySetInnerHTML={{ __html: item.title }}
					/> 
				postingTags.push(
					<div key={item.postingno} id={item.postingno} className="result-row" >
						<Posting
							key={postingsCount}
							wa={props.wa}
							moduleId={props.moduleId}
							mode="list"
							posting={item}
							getPostings={() => {
								console.log(item.title);
							}}
							hideMember={wau.getProperty(props.hideMember, false)}
							content={null}
							title={title}
							paddingLeft={0}
							postingQuote={props.postingQuote}
							copyToClipboard={props.copyToClipboard}
							showDialogAnswer={props.showDialogAnswer}
							setTopic={() => {}}
							onClick={(e) => {showThread(e, item.threadno, item.postingno)}}
						/>
					</div>
				);
			}
		);
		if (postingsCount > 0) {
			postingTags.push(
				<div key="posting-count" className="posting-count" >
					{postingsCount} Beiträge
				</div>
			)
		}
		return postingTags;
	}
	
	/*
	function makePostingsOld(source) {
		let postingTags = [];
		let clipboard = null;
		let title = null;
		let posting = null;
		let author = null;
		let postingInfo = null;
		postingsCount = 0;
		source.forEach(
			function (item, index) {
				postingsCount++;
				
				if (withAvatar) {
					author = <span
						id={'author-' + item.postingno}
						dangerouslySetInnerHTML={{ __html: item.membername + ' - ' + item.postdate }}
					/>
					postingInfo = 	<span 
										key="posting-info" 
										className="bold"
									>
										<Avatar 
											memberLevel={item.memberlevel} 
											memberName={author} 
											avatarText={item.avatartext} 
										/>
									</span>
				}
				else {
					postingInfo = <span key="posting-info" className="posting-date">{item.postdate}</span>
				}
				title = 
					<span 
						key={'title-' + item.postingno} 
						id={'title-' + item.postingno}
						dangerouslySetInnerHTML={{ __html: item.title }}
					/> 
				clipboard = <i 
					key="posting-clipboard" 
					className="bi bi-copy" 
					title="Beitrag in die Zwischenablage kopieren" 
					onClick={e => copyToClipboard(e, item.title, item.membername, item.postdate)}>

				</i>
				
				if (withAvatar) {
					posting = 
						<div className="title-div">
							<span onClick={e => showThread(e, item.threadno, item.postingno)}>
								{postingInfo}
								<span className="posting-with-avatar">
									{title}
									{clipboard}
								</span>
							</span>
						</div>
				}
				else {
					posting = 
						<span className="posting">
							{postingInfo}
							{title}
							{clipboard}
						</span>
				}
				postingTags.push(
					<div key={item.postingno} id={item.postingno} className="result-row" >
						{posting} 
						
					</div>);
			}
		);

		if (postingsCount > 0) {
			postingTags.push(
				<div key="posting-count" className="posting-count">
					{postingsCount} Beiträge
				</div>
			)
		}
		return postingTags;
	}
	*/

	return (
		<div id={props.moduleId + '-' + props.id} className={`div-postings-list  ${(typeof props.classes == 'undefined') ? '' : props.classes} `} >
			<div className="div-result">
				{makePostings(postingSource, props.addUrl)}
			</div>
		</div>
	);


}
